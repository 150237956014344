import * as Yup from 'yup'

export const usuarioModuloFazendaEsquema = Yup.object().shape({
    editar: Yup.boolean(),
    usuario: Yup.object().required('Campo obrigatório').nullable(),
    modulos: Yup.mixed().when('editar', {
        is: true,
        then: Yup.object().required('Campo obrigatório').nullable(),
        otherwise: Yup.array().min(1, 'Campo obrigatório').nullable(),
    }),
    clientes: Yup.mixed().when('editar', {
        is: true,
        then: Yup.object().required('Campo obrigatório').nullable(),
        otherwise: Yup.array().min(1, 'Campo obrigatório').nullable(),
    }),
})
