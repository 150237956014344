import React, { useState, useEffect } from 'react'
import { Backdrop, CircularProgress } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.modal + 1,
        color: '#fff',
        display: 'flex',
        flexDirection: 'column',
        justify: 'center',
    },
}))

const Loading = (props) => {
    const classes = useStyles()
    const [open, setOpen] = useState(false)

    useEffect(() => {
        setOpen(props.open)
    }, [props.open])

    return (
        <div>
            <Backdrop className={classes.backdrop} open={open}>
                <CircularProgress />
                <Typography>{props.texto}</Typography>
            </Backdrop>
        </div>
    )
}

export default Loading
