import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import {
  Card,
  TextField,
  Grid,
  Container,
  CardContent,
  FormControl,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import usuarioService from "../../services/usuarioService";
import perfilService from "../../services/PerfilService";
import Alert from "@material-ui/lab/Alert";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { cadastroUsuarioEsquema } from "./usuarioCadastro.validate";
import { yupResolver } from "@hookform/resolvers/yup";
import Page from "../../components/Page";
import Titulo from "../../components/Titulo";
import FooterBottons from "../../components/FooterBottons/FooterBottons";
import ToastHelper from "../../utils/toastHelper";
import { validatePermission } from "../../permissoes/permissionHelper";
import { PERMISSAO } from "../../permissoes/permissoes";
import { Store } from "../../store";
import watch from "redux-watch";
import TelefoneInput from "../../components/InputTelephoneNumber/InputTelephone";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  margin: {
    margin: theme.spacing(1),
  },
  card: {
    marginTop: "15px",
  },
  formControl: {
    margin: theme.spacing(2),
    paddingRight: "15px",
    minWidth: "100%",
  },
  errors: {
    color: "red",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justify: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  boxLoading: {
    paddingLeft: "45%",
  },
  alert: {
    width: "100%",
  },
}));

export default function CadastroUsuario(props) {
  const classes = useStyles();
  let history = useHistory();

  const {
    handleSubmit,
    control,
    setValue,
    watch: watchReactHookForm,
    formState: { errors },
  } = useForm({
    defaultValues: {
      usuario: "",
      nomeCompleto: "",
      email: "",
      senha: "",
      senhaConfirmar: "",
      perfil: null,
      editar: false,
      numeroContato: "",
    },
    resolver: yupResolver(cadastroUsuarioEsquema),
  });

  const [mensagemErroCadastro, setMensagemErroCadastro] = useState("");
  const [titulo, setTitulo] = useState("Cadastro de Usuário");
  const [visualizar, setVisualizar] = useState(false);
  const [opcoesPerfil, setOpcoesPerfil] = useState([]);

  const {
    match: { params },
  } = props;

  const buscarPerfis = async () => {
    await perfilService
      .buscarTodosPerfis()
      .then((result) => {
        var perfis = result.data;
        setOpcoesPerfil(perfis);
        if (perfis.length === 1) {
          setValue("perfil", perfis[0]);
        }
      })
      .catch((error) => {
        ToastHelper.error("Erro ao buscar perfis");
      });
  };

  const buscarUsuario = async () => {
    const w = watch(Store.getState, "permissao.permissoes");
    Store.subscribe(
      w((newVal, oldVal, objectPath) => {
        if (!validatePermission(PERMISSAO.MODULO_USUARIO_CRIAR, newVal)) {
          history.push("/");
        }
      })
    );

    if (params.id) {
      if (params.modo === "visualizar") {
        setValue("editar", false);
        setVisualizar(true);
        setTitulo("Visualizar Usuário");
        w((newVal, oldVal, objectPath) => {
          if (!validatePermission(PERMISSAO.MODULO_USUARIO_LEITURA, newVal)) {
            history.push("/");
          }
        });
      }
      if (params.modo === "editar") {
        setValue("editar", true);
        setVisualizar(false);
        setTitulo("Edição de Usuário");
        w((newVal, oldVal, objectPath) => {
          if (!validatePermission(PERMISSAO.MODULO_USUARIO_ATUALIZAR, newVal)) {
            history.push("/");
          }
        });
      }
      usuarioService
        .buscarUsuario(
          params.id,
          JSON.parse(localStorage.getItem("fazenda")).id
        )
        .then((result) => {
          var usuario = result.data;
          setValue("usuario", usuario.usuario);
          setValue("nomeCompleto", usuario.nomeCompleto);
          setValue("email", usuario.email);
          setValue("numeroContato", usuario.numeroContato || "");
          setValue("perfil", {
            id: usuario.perfil.id,
            nome: usuario.perfil.nome,
          });
        })
        .catch((error) => {
          ToastHelper.error(error.response.data);
        });
    }
  };

  const formatarNumero = (number) => {
    if (number.length > 0) {
      return number.replace(/[^0-9]/g, "");
    }
    return "";
  };

  const onSubmit = async (dados) => {
    dados.fazendaId = JSON.parse(localStorage.getItem("fazenda")).id;
    dados.perfilId = dados.perfil.id;
    dados.numeroContato = formatarNumero(dados.numeroContato);

    if (params.id) {
      dados.id = params.id;
      usuarioService
        .atualizar(dados)
        .then((result) => {
          ToastHelper.success("Usuário atualizado com sucesso!");
          history.goBack();
        })
        .catch((error) => {
          setMensagemErroCadastro(error.response.data);
        });
    } else {
      usuarioService
        .cadastro(dados)
        .then((result) => {
          ToastHelper.success("Usuário cadastrado com sucesso!");
          history.goBack();
        })
        .catch((error) => {
          setMensagemErroCadastro(error.response.data);
        });
    }
  };

  useEffect(() => {
    async function init() {
      buscarPerfis();
      buscarUsuario();
    }
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Page className={classes.root} title="Usuário">
      <Container maxWidth={"xl"}>
        <Titulo titulo={titulo} />
        <form
          key={1}
          className={classes.form}
          onSubmit={handleSubmit(onSubmit)}
          id="usuarioModuloCliente"
        >
          <Card className={classes.card}>
            <CardContent>
              <Grid container item xs={12} display="flex">
                {mensagemErroCadastro && (
                  <Alert
                    severity="error"
                    onClose={() => {
                      setMensagemErroCadastro("");
                    }}
                    className={classes.alert}
                  >
                    {mensagemErroCadastro}
                  </Alert>
                )}
                <FormControl className={classes.formControl}>
                  <Controller
                    control={control}
                    id="usuario"
                    name="usuario"
                    render={({ field }) => (
                      <TextField
                        {...field}
                        variant="standard"
                        margin="normal"
                        fullWidth
                        label="Usuário *"
                        autoComplete="usuario"
                        autoFocus
                        disabled={watchReactHookForm("editar") || visualizar}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    )}
                  />
                  <p className={classes.errors}>
                    {errors.usuario ? errors.usuario.message : ""}
                  </p>
                </FormControl>
              </Grid>
              <Grid container item xs={12} display="flex">
                <FormControl className={classes.formControl}>
                  <Controller
                    control={control}
                    id="nomeCompleto"
                    name="nomeCompleto"
                    render={({ field }) => (
                      <TextField
                        {...field}
                        variant="standard"
                        margin="normal"
                        fullWidth
                        label="Nome Completo *"
                        autoComplete="nomeCompleto"
                        autoFocus
                        disabled={visualizar}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    )}
                  />
                  <p className={classes.errors}>
                    {errors.nomeCompleto ? errors.nomeCompleto.message : ""}
                  </p>
                </FormControl>
              </Grid>
              <Grid container item xs={12} display="flex">
                <FormControl className={classes.formControl}>
                  <Controller
                    control={control}
                    id="email"
                    name="email"
                    render={({ field }) => (
                      <TextField
                        {...field}
                        variant="standard"
                        margin="normal"
                        fullWidth
                        label="Email *"
                        autoComplete="email"
                        autoFocus
                        disabled={watchReactHookForm("editar") || visualizar}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    )}
                  />
                  <p className={classes.errors}>
                    {errors.email ? errors.email.message : ""}
                  </p>
                </FormControl>
              </Grid>
              <Grid container item xs={12} display="flex">
                <FormControl className={classes.formControl}>
                  <Controller
                    control={control}
                    id="numeroContato"
                    name="numeroContato"
                    render={({ field }, ref) => (
                      <TelefoneInput
                        {...field}
                        variant="standard"
                        margin="normal"
                        fullWidth
                        label="Número de Contato"
                        autoFocus
                        disabled={params.modo === "visualizar"}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        ref={ref}
                      />
                    )}
                  />{" "}
                  <p className={classes.errors}>
                    {errors.numeroContato ? errors.numeroContato.message : ""}
                  </p>
                </FormControl>
              </Grid>
              <Grid container item xs={12} display="flex">
                <FormControl className={classes.formControl}>
                  <Controller
                    control={control}
                    id="perfil"
                    name="perfil"
                    render={({ field }) => (
                      <Autocomplete
                        {...field}
                        options={opcoesPerfil}
                        disabled={visualizar}
                        getOptionLabel={(option) => option.nome || ""}
                        onChange={(_, data) => {
                          field.onChange(data);
                        }}
                        size="small"
                        getOptionSelected={(option, value) =>
                          option?.id === value?.id
                        }
                        value={field.value}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="standard"
                            label="Perfil*"
                            placeholder="Selecione"
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        )}
                      />
                    )}
                  />
                  <p className={classes.errors}>
                    {errors.perfil ? errors.perfil.message : ""}
                  </p>
                </FormControl>
              </Grid>
              {(watchReactHookForm("editar") || !visualizar) && (
                <>
                  <Grid container item xs={12} display="flex">
                    <FormControl className={classes.formControl}>
                      <Controller
                        control={control}
                        id="senha"
                        name="senha"
                        render={({ field }) => (
                          <TextField
                            {...field}
                            variant="standard"
                            margin="normal"
                            fullWidth
                            label="Senha *"
                            autoComplete="senha"
                            autoFocus
                            type="password"
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        )}
                      />
                      <p className={classes.errors}>
                        {errors.senha ? errors.senha.message : ""}
                      </p>
                    </FormControl>
                  </Grid>
                  <Grid container item xs={12} display="flex">
                    <FormControl className={classes.formControl}>
                      <Controller
                        control={control}
                        id="senhaConfirmar"
                        name="senhaConfirmar"
                        render={({ field }) => (
                          <TextField
                            {...field}
                            variant="standard"
                            margin="normal"
                            fullWidth
                            label="Confirmar Senha *"
                            autoComplete="senhaConfirmar"
                            autoFocus
                            type="password"
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        )}
                      />
                      <p className={classes.errors}>
                        {errors.senhaConfirmar
                          ? errors.senhaConfirmar.message
                          : ""}
                      </p>
                    </FormControl>
                  </Grid>
                </>
              )}
            </CardContent>
          </Card>
          <FooterBottons desabilitaSubmit={visualizar} />
        </form>
      </Container>
    </Page>
  );
}
