import React, {useEffect} from 'react';

export default function RecuperarAcesso(props) {
    const { match: { params } } = props;
    useEffect(() => {
        window.location.href = `sgagri://app/recuperarAcesso/${params?.token}`
        // eslint-disable-next-line
        return () => props = null
    }, [])
    return <></>;
}