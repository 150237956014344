import * as Yup from 'yup'

export const usuarioSenhaEsquema = Yup.object().shape({
    senhaAntiga: Yup.string().required('Campo obrigatório'),
    senhaNova: Yup.string()
    .matches(/^(?=.*[A-Z])(?=.*[0-9])(?=.*[\W|_])(?=.{8,})/, "A senha deve conter ao menos 8 caracteres sendo, uma letra maiúscula, um número e um caracter especial")
    .required('Campo obrigatório'),
    senhaNovaConfirmar: Yup.string().required('Campo obrigatório').test('passwords-match', 'Senhas precisam ser iguais', function(value){
        return this.parent.senhaNova === value
    })
})