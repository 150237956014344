import React from "react";
import { Grid } from "@material-ui/core";
import AppCard from "./AppCard";

export const AppCards = React.memo(function HighlightCard({
  modulos,
  acessarModulo,
}) {
  return (
    <>
      <Grid container spacing={1}>
        {modulos.map((item, indice) => (
          <Grid item xs={12} sm={6} md={4} lg={4} xl={3} key={indice.toString()}>
            <AppCard
              key={item.id}
              cover={item.capa}
              title={item.titulo}
              description={item.descricao}
              url={item.url}
              ativo={item.ativo}
              acessarModulo={acessarModulo}
            />
          </Grid>
        ))}
      </Grid>
    </>
  );
});

export default AppCards;
