import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { ListItem, makeStyles, Divider } from "@material-ui/core";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import ListSubheader from "@material-ui/core/ListSubheader";
import List from "@material-ui/core/List";
import Tooltip from "@material-ui/core/Tooltip";
import { validatePermission } from "../../permissoes/permissionHelper";
import PermissaoService from "../../services/permisaoService";
import { STORE_PERMISSAO } from "../../store/actions/permissao";
import { useDispatch } from "react-redux";
import { Store } from "../../store";
import watch from "redux-watch";
import { STORE_FAZENDA } from "../../store/actions/fazenda";
import { connect } from "react-redux";

const useStyles = makeStyles((theme) => ({
  item: {
    display: "flex",
    paddingTop: 0,
    paddingBottom: 0,
  },
  button: {
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightMedium,
    justify: "flex-start",
    letterSpacing: 0,
    textTransform: "none",
    width: "100%",
  },
  nested: {
    padding: "5px",
    marginRight: "5px",
    "&:hover": {
      backgroundColor: "#C3F2C4",
    },
  },
  textSubMenu: {
    color: "#949494",
  },
  textSubMenuSelecionado: {
    color: "#2E7D32 !important",
  },
  icon: {
    maxHeight: "18px",
    minWidth: "18px",
    marginRight: theme.spacing(1),
    marginLeft: "3px",
    color: "#949494",
  },
  iconMarginRigth: {
    maxHeight: "25px",
    minWidth: "25px",
    marginRight: "25px",
    marginLeft: "3px",
    color: "#949494",
  },
  iconSelected: {
    maxHeight: "18px",
    minWidth: "18px",
    marginRight: theme.spacing(1),
    marginLeft: "3px",
    color: "#2E7D32 !important",
  },
  iconMarginRigthSelected: {
    maxHeight: "25px",
    minWidth: "25px",
    marginRight: "25px",
    marginLeft: "3px",
    color: "#2E7D32 !important",
  },
  title: {
    marginRight: "auto",
  },
  active: {
    "& $title": {
      fontWeight: theme.typography.fontWeightMedium,
    },
  },
  divider: {
    marginTop: "1px",
    backgroundColor: "#2E7D32",
    marginBottom: "1px",
  },
}));

const NavItem = ({ className, href, icon: Icon, getItems, title, ...rest }) => {
  let history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleClick = (e) => {
    setMenuPaiSelecionado(e.index);
    setEstado({ [e.name]: !estado[e.name] });
  };

  const handleClickItem = (e, item) => {
    setMenuSelecionado(item.index);
    history.push(item.href);
  };

  const items = getItems();
  const [estado, setEstado] = useState({});
  const [menuSelecionado, setMenuSelecionado] = useState({});
  const [menuPaiSelecionado, setMenuPaiSelecionado] = useState({});

  useEffect(() => {
    async function init() {
      let fazenda = JSON.parse(localStorage.getItem("fazenda"));

      localStorage.setItem("fazenda", JSON.stringify(fazenda));
      dispatch({
        type: STORE_FAZENDA,
        fazenda: fazenda,
      });

      const w = watch(Store.getState, "fazenda.fazendaSelecionada");
      Store.subscribe(
        w((newVal, oldVal, objectPath) => {
          getPermissoes(newVal);
        })
      );

      getPermissoes(fazenda);
    }
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getPermissoes = (fazenda) => {
    if (fazenda) {
      PermissaoService.buscarTudo(fazenda.id)
        .then((result) => {
          dispatch({
            type: STORE_PERMISSAO,
            permissao: result.data,
          });
        })
        .catch((error) => {
          //setIsLoading(false);
        });
    }
  };

  function temPermissao(element, index, array) {
    var modulos = element.subitems.map((x) => x.modulo);
    return validatePermission(modulos, rest.permissoes);
  }

  return (
    <div>
      {items.list.map((list, index) => {
        return (
          list.items.some(temPermissao) && (
            <div key={index}>
              <List
                className={classes.root}
                key={list.id}
                subheader={<ListSubheader>{list.title}</ListSubheader>}
              >
                {list.items.map((item) => {
                  return (
                    <div key={item.id}>
                      {item.subitems != null ? (
                        <div key={item.id}>
                          <ListItem
                            button
                            mt={3}
                            key={item.id}
                            className={classes.nested}
                            onClick={handleClick.bind(this, item)}
                          >
                            <Tooltip title={item.name}>
                              <item.icon
                                className={
                                  rest.open
                                    ? menuPaiSelecionado === item?.index
                                      ? classes.iconSelected
                                      : classes.icon
                                    : menuPaiSelecionado === item?.index
                                    ? classes.iconMarginRigthSelected
                                    : classes.iconMarginRigth
                                }
                              />
                            </Tooltip>
                            <ListItemText
                              className={
                                menuPaiSelecionado === item?.index
                                  ? classes.textSubMenuSelecionado
                                  : classes.textSubMenu
                              }
                              primary={item.name}
                            />
                          </ListItem>
                          <Collapse
                            key={list.items.id}
                            component="li"
                            in={estado[item.name]}
                            timeout="auto"
                            unmountOnExit
                          >
                            <Divider className={classes.divider} />
                            <List disablePadding>
                              {item.subitems.map((sitem) => {
                                return (
                                  validatePermission(
                                    sitem.modulo,
                                    rest.permissoes
                                  ) && (
                                    <ListItem
                                      button
                                      key={sitem.id}
                                      className={classes.nested}
                                      onClick={(e) => handleClickItem(e, sitem)}
                                    >
                                      <Tooltip title={sitem.name}>
                                        <sitem.icon
                                          className={
                                            rest.open
                                              ? menuSelecionado === sitem?.index
                                                ? classes.iconSelected
                                                : classes.icon
                                              : menuSelecionado === sitem?.index
                                              ? classes.iconMarginRigthSelected
                                              : classes.iconMarginRigth
                                          }
                                        />
                                      </Tooltip>
                                      <ListItemText
                                        className={
                                          menuSelecionado === sitem?.index
                                            ? classes.textSubMenuSelecionado
                                            : classes.textSubMenu
                                        }
                                        key={sitem.id}
                                        primary={sitem.name}
                                      />
                                    </ListItem>
                                  )
                                );
                              })}
                            </List>

                            <Divider className={classes.divider} />
                          </Collapse>{" "}
                        </div>
                      ) : (
                        <ListItem
                          button
                          className={classes.nested}
                          onClick={(e, item) => handleClick(e, item)}
                          key={item.id}
                        >
                          <ListItemText
                            primary={item.name}
                            className={classes.nested}
                          />
                        </ListItem>
                      )}
                    </div>
                  );
                })}
              </List>
            </div>
          )
        );
      })}
    </div>
  );
};

NavItem.propTypes = {
  className: PropTypes.string,
  href: PropTypes.string,
  icon: PropTypes.elementType,
  title: PropTypes.string,
};

const mapStateToProps = (state) => {
  return {
    permissoes: state.permissao.permissoes,
  };
};

export default connect(mapStateToProps)(NavItem);
