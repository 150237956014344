import { validatePermission } from "../../permissoes/permissionHelper"

export function getTelasDoMenuRapido(menuAtual, permissoes) {
    let menuRapido = [];
    let menu = menuAtual().list;
    menu.forEach((item) => {
        if (item.items) {
            if (item.items[0].subitems.length > 0) {
                item.items[0].subitems.map((pag) => {
                    if (validatePermission(pag.modulo, permissoes)) {
                        menuRapido.push({
                            value: pag.href,
                            label: pag.name,
                            id: pag.id,
                            icon: pag.icon,
                        })
                    }
                    return null
                });
            }
        }
    });
    return menuRapido.sort(function (a, b) {
        return a.label > b.label ? 1 : -1;
    });
}
