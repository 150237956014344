import * as Yup from "yup";

export const ClienteEsquema = Yup.object().shape({
  nomeRazao: Yup.string().required("Campo obrigatório"),
  hectares: Yup.string().required("Campo obrigatório"),
});

export const FazendaDuplicarEsquema = Yup.object().shape({
  fazenda: Yup.object().nullable().required("Campo obrigatório"),
  nomeRazao: Yup.string().required("Campo obrigatório"),
  hectares: Yup.string().required("Campo obrigatório"),
});
