import api from "./portalAPI";
import apiRedefinirSenha from "./portalApiRedefinirSenha";

const service = {
  cadastro(usuario) {
    return api.post(`/Usuario`, usuario);
  },
  buscarUsuario(id, fazendaId) {
    return api.get(`/Usuario/${id}/${fazendaId}`);
  },
  atualizar(usuario) {
    return api.put(`/Usuario`, usuario);
  },
  buscarTudo(param) {
    return api.get(`/Usuario${param}`);
  },
  ativarDesativar(id) {
    return api.put(`/Usuario/AtivarDesativar`, id);
  },
  deletar(id) {
    return api.delete(`/Usuario/${id}`);
  },
  alterarSenha(alterarSenha) {
    return api.post(`/Usuario/AlterarSenha`, alterarSenha);
  },
  redefinirSenhaLogin(redefinirSenha) {
    return apiRedefinirSenha.post(
      `/Usuario/RedefinirSenhaLogin`,
      redefinirSenha
    );
  },
  buscarUsuarios(filtro) {
    return api.get(`/Usuario/BuscarUsuarios/${filtro}`);
  },
  validarLinkRecuperarAcesso(token) {
    return api.get(`/ForgetLogin/ValidarLinkRecuperarAcesso/${token}`);
  },
};

export default service;
