import UsuarioModuloFazenda from "../pages/usuarioModuloFazenda/index"
import CadastroUsuarioModuloFazenda from "../pages/usuarioModuloFazenda/usuarioModuloFazendaCadastro"

export const UsuarioModuloFazendaRoutes = [
	{
		path: "/usuarioModuloFazenda/listagem",
		key: "ASSOCIACAO_MODULO_USUARIO_LISTAGEM",
		exact: true,
		component: UsuarioModuloFazenda,
	},
	{
		path: "/usuarioModuloFazenda/cadastro",
		key: "ASSOCIACAO_MODULO_USUARIO_CADASTRO",
		exact: true,
		component: CadastroUsuarioModuloFazenda,
	},
	{
		path: "/usuarioModuloFazenda/:modo(editar|visualizar)/:id([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})",
		key: "USUARIO_VISUALIZAR_EDICAO",
		exact: true,
		component: CadastroUsuarioModuloFazenda,
	},
];
