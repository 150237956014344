import api from "./portalAPI";

const service = {
    buscarTudo(id) {
        return api.get(`/Permissao/${id}`);
    },
    
};

export default service;
