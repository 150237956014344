import { combineReducers } from "redux";
import { fazenda } from "./fazenda";
import { usuario } from "./usuario";
import { permissao } from "./permissao";
import { perfil } from "./perfil";
import { cliente } from "./cliente";
import { usuarioModuloFazenda } from "./usuarioModuloFazenda";
import { usuarioPerfilFazenda } from "./usuarioPerfilFazenda";

export const Reducers = combineReducers({
  cliente,
  fazenda,
  usuario,
  permissao,
  perfil,
  usuarioModuloFazenda,
  usuarioPerfilFazenda,
});
