import React, { useState, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useHistory } from "react-router-dom";
import { PerfilEsquema } from "./Perfil.validate";
import Page from "../../components/Page";
import FooterBottons from "../../components/FooterBottons/FooterBottons";
import {
  Container,
  Card,
  Grid,
  CardContent,
  TextField,
  makeStyles,
  FormControl,
} from "@material-ui/core";
import Titulo from "../../components/Titulo";
import ToastHelper from "../../utils/toastHelper";
import PerfilService from "../../services/PerfilService";
import { Paper } from "@mui/material";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  margin: {
    margin: theme.spacing(1),
  },
  card: {
    marginTop: "15px",
  },
  formControl: {
    margin: theme.spacing(2),
    paddingRight: "15px",
    minWidth: "100%",
  },
  errors: {
    color: "red",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justify: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  boxLoading: {
    paddingLeft: "45%",
  },
  gridFuncionalidades: {
    margin: "5px",
    width: "100%",
    backgroundColor: "#F4F6F8 !important",
    padding: "5px",
    display: "flex",
  },
  nomeFuncionalidade: {
    marginRight: "30px",
  },
  containerFuncionalidades: {
    textAlign: "-webkit-center",
  },
  gridNomefuncionalidade: {
    display: "flex",
    marginLeft: theme.spacing(1),
    alignItems: "center",
    justify: "flex-start",
  },
}));

export const PerfilCadastro = (props) => {
  const history = useHistory();
  const classes = useStyles();
  const {
    match: { params },
  } = props;
  const {
    handleSubmit,
    setValue,
    formState: { errors },
    control,
  } = useForm({
    defaultValues: {
      id: "",
      nome: "",
    },
    resolver: yupResolver(PerfilEsquema),
  });
  const [selecionarTudo, setSelecionarTudo] = useState(false);
  const [permissoesPorGrupo, setPermissoesPorGrupo] = useState([]);
  const [visualizar, setVisualizar] = useState(false);

  useEffect(() => {
    async function init() {
      //metodos iniciais
      await buscarFuncionalidadesPermissoes();
      if (params.id) {
        setVisualizar(params.modo === "visualizar")
      }
    }
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    updateSelecionarTudo(permissoesPorGrupo);
  }, [permissoesPorGrupo]);

  const buscarPerfilPorId = async (id, permissoesPorGrupo) => {
    PerfilService.buscarPorId(id)
      .then((response) => {
        if (response.status === 200) {
          setValue("nome", response.data.perfil.nome);
          setValue("id", response.data.perfil.id);
          if (response.data.permissaoFuncionalidades.length > 0) {
            let novasPermissoesPorGrupo = [...permissoesPorGrupo];

            response.data.permissaoFuncionalidades.forEach(
              (permissaoFuncionalidades) => {
                permissaoFuncionalidades.forEach((permi) => {
                  novasPermissoesPorGrupo.forEach((permissoes) => {
                    permissoes.forEach((e) => {
                      if (e.id === permi.id) {
                        e.checked = true;
                      }
                    });
                  });
                });
              }
            );
            setPermissoesPorGrupo([]);
            setPermissoesPorGrupo(novasPermissoesPorGrupo);
          }
        }
      })
      .catch((error) => {
        ToastHelper.error(error.response.data);
      });
  };

  const buscarFuncionalidadesPermissoes = async (dados) => {
    await PerfilService.buscarFuncionalidadesPermissoes().then(
      async (response) => {
        setPermissoesPorGrupo(response.data);
        if (params.id) {
          await buscarPerfilPorId(params.id, response.data);
        }
      }
    );
  };

  const submit = async (dados) => {
    let permissoes = await [...permissoesPorGrupo];

    let funcionalidadesPermissoes = [];
    permissoes.forEach(async (permis) => {
      let funcionalidade = {
        idFuncionalidade: permis[0].funcionalidadeId,
        permissoes: [],
      };
      permis.forEach((e) => {
        if (e.checked) {
          funcionalidade.permissoes.push(e.id);
        }
      });
      funcionalidadesPermissoes.push(funcionalidade);
    });

    let parametros = {
      perfilNome: dados.nome,
      funcionalidadesPermissoes: funcionalidadesPermissoes,
    };

    if (!params.id) {
      await PerfilService.cadastrar(parametros)
        .then((response) => {
          ToastHelper.success("Perfil cadastrado com sucesso!");
          history.goBack();
        })
        .catch(function (error) {
          if (error.response) {
            // Solicitação feita com resposta do servidor
            ToastHelper.error(error.response.data);
          } else if (error.request) {
            // Solicitação feita sem resposta do servidor
          } else {
            // Erro na solicitação
          }
        });
    } else {
      parametros.id = params.id;
      await PerfilService.atualizar(parametros)
        .then((response) => {
          ToastHelper.success("Perfil editado com sucesso!");
          history.goBack();
        })
        .catch(function (error) {
          if (error.response) {
            // Solicitação feita com resposta do servidor
            ToastHelper.error(error.response.data);
          } else if (error.request) {
            // Solicitação feita sem resposta do servidor
          } else {
            // Erro na solicitação
          }
        });
    }
  };

  const changeCheckPermissao = async (
    indexGrupo,
    indexPermissao,
    isChecked
  ) => {
    let permissoesAlteradas = [...permissoesPorGrupo];
    permissoesAlteradas[indexGrupo][indexPermissao].checked = isChecked;
    setPermissoesPorGrupo(permissoesAlteradas);
  };

  const updateSelecionarTudo = (permissoesPorGrupo) => {
    const numChecked = permissoesPorGrupo.filter(
      (grupo) => grupo.filter((item) => item.checked).length > 0
    ).length;
    const numNotChecked = permissoesPorGrupo.filter(
      (grupo) => grupo.filter((item) => !item.checked).length > 0
    ).length;
    if (numChecked > 0 && numNotChecked > 0) setSelecionarTudo(undefined);
    else if (numChecked > 0 && numNotChecked === 0) setSelecionarTudo(true);
    else setSelecionarTudo(false);
  };

  const changeSelecionarTudo = async (event, checked) => {
    if (checked !== undefined) {
      setSelecionarTudo(checked);
      let permissoesAlteradas = [...permissoesPorGrupo];
      permissoesAlteradas.forEach((permissoes) => {
        permissoes.forEach((e) => (e.checked = checked));
      });
      setPermissoesPorGrupo([]);
      setPermissoesPorGrupo(permissoesAlteradas);
    }
  };

  const title = params.modo === "visualizar"
    ? "Visualizar Perfil"
    : params.modo === "editar"
      ? "Editar Perfil"
      : "Cadastrar Perfil";

  return (
    <Page className={classes.root} title={title}>
      <Container maxWidth={"xl"}>
        <Titulo titulo={title} />
        <form
          key={1}
          className={classes.form}
          onSubmit={handleSubmit(submit)}
          id="formGSC"
        >
          <Card className={classes.card}>
            <CardContent>
              <Grid item xs={12} display="flex">
                <FormControl className={classes.formControl}>
                  <Controller
                    control={control}
                    id="nome"
                    name="nome"
                    render={({ field }) => (
                      <TextField
                        {...field}
                        variant="outlined"
                        fullWidth
                        label="Nome*"
                        placeholder="Digite o nome"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        disabled={visualizar}
                      />
                    )}
                  />
                  <p className={classes.errors}>
                    {errors.nome ? errors.nome.message : ""}
                  </p>
                </FormControl>
              </Grid>
              <Grid
                className={classes.containerFuncionalidades}
                container
                alignItems="center"
                justify="center"
              >
                <Grid item xs={12} display="flex">
                  <Paper className={classes.gridFuncionalidades} display="flex">
                    <Grid
                      item
                      xs={5}
                      className={classes.gridNomefuncionalidade}
                      display="flex"
                    >
                      <h4 className={classes.nomeFuncionalidade}>
                        Selecionar Tudo
                      </h4>
                    </Grid>
                    <Grid item xs={7} display="flex">
                      <Checkbox
                        color="primary"
                        checked={selecionarTudo || false}
                        indeterminate={selecionarTudo === undefined}
                        onChange={(event, checked) =>
                          changeSelecionarTudo(event, checked)
                        }
                        disabled={visualizar}
                      />
                    </Grid>
                  </Paper>
                </Grid>
                {permissoesPorGrupo.map((permissoes, indexGrupo) => {
                  return (
                    <Grid
                      item
                      xs={12}
                      display="flex"
                      key={indexGrupo.toString()}
                    >
                      <Paper
                        className={classes.gridFuncionalidades}
                        display="flex"
                      >
                        <Grid
                          item
                          xs={5}
                          className={classes.gridNomefuncionalidade}
                          display="flex"
                        >
                          <h4 className={classes.nomeFuncionalidade}>
                            {permissoes[0].funcionalidade.nome}
                          </h4>
                        </Grid>
                        <Grid item xs={7} display="flex">
                          {permissoes.map((item, indexPermissao) => {
                            return (
                              <FormControlLabel
                                key={indexPermissao.toString()}
                                name={item.nome}
                                id={item.nome}
                                control={
                                  <Checkbox
                                    color="primary"
                                    checked={item.checked || false}
                                    onChange={(_, checked) =>
                                      changeCheckPermissao(
                                        indexGrupo,
                                        indexPermissao,
                                        checked
                                      )
                                    }
                                    disabled={visualizar}
                                  />
                                }
                                label={item.nome}
                              />
                            );
                          })}
                        </Grid>
                      </Paper>
                    </Grid>
                  );
                })}
              </Grid>
            </CardContent>
          </Card>
          <FooterBottons
            externo={props.externo}
            desabilitaSubmit={visualizar}
          />
        </form>
      </Container>
    </Page>
  );
};
