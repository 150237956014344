import React, { useState } from "react";
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Grid, Link, Paper, TextField } from '@material-ui/core';
import { useStyles, style } from "./useStylesModal";
import { userSchema } from "./Validations/userValidations";
import { Stepper } from "@mui/material";
import forgetService from "../../../services/forgetService";

export default function KeepMountedModal() {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [isValid, setIsValid] = useState(true);
    const [yupError, setYupError] = useState('');
    const [activeStep, setActiveStep] = useState(0);
    const handleOpen = () => {
        setActiveStep(0);
        setIsValid(true);
        setYupError(null);
        setOpen(true);
    }
    const handleClose = () => setOpen(false);

    const validateEmail = async (event) => {
        event.preventDefault()
        const emailForm = event.target[0].value;
        const formData = { email: emailForm }

        try {
            await userSchema.validate(formData);
            setIsValid(true);
            setYupError(null);
            forgetService
                .forget(emailForm);
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        } catch (err) {
            setIsValid(false);
            setYupError(err.errors[0]);
        }
        return false;
    }

    const InputError = ({ text }) => (
        <span style={{ color: 'red' }}>{text}</span>
    )

    const steps = [
        {
            title:
                <>
                    <Typography style={{ fontWeight: 'bold', color: '#000000', marginTop: 20, fontSize: 22, lineHeight: 1.2 }}>
                        É necessário validar a sua identidade
                    </Typography>
                    <Typography style={{ color: '#333333', marginTop: 16, fontSize: 16, lineHeight: 1.2 }}>
                        Informe seu e-mail para continuar
                    </Typography>
                </>,
            content:
                <>
                    <form className={classes.form} onSubmit={validateEmail}>
                        <TextField
                            type="email"
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="E-mail"
                            name="email"
                        />
                        {!isValid && yupError !== '' ? <InputError text={yupError} /> : null}
                        <Grid container style={{ marginTop: 30 }}>
                            <Grid item xs>
                                <Button variant="contained" color="primary" onClick={() => setOpen(false)}>Cancelar</Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    className={classes.submit}
                                    sx={{ backgroundColor: 'green' }}
                                >
                                    Recuperar
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </>,
        },
        {
            title:
                <>
                    <Typography style={{ fontWeight: 'bold', color: '#000000', marginTop: 20, fontSize: 22, lineHeight: 1.2 }}>
                        Pronto!
                    </Typography>
                    <Typography style={{ color: '#333333', marginTop: 16, fontSize: 16, lineHeight: 1.2 }}>
                        Se o e-mail que você informou for um e-mail válido, enviaremos as instruções de recuperação.<p>Se não encontrar em sua caixa de entrada, verifique na caixa de SPAM ou Lixo Eletrônico.</p>
                    </Typography>
                </>,
            content:
                <Grid container style={{ marginTop: 30 }}>
                    <Grid item xs>
                        <Button variant="contained" color="primary" onClick={() => setOpen(false)}>Ok</Button>
                    </Grid>
                </Grid>
        },
    ];

    const maxSteps = steps.length;
    return (
        <div>
            <Link href="#" variant="body2" onClick={handleOpen}>
                Esqueceu a senha?
            </Link>
            <Modal
                keepMounted
                open={open}
                onClose={handleClose}
                aria-labelledby="keep-mounted-modal-title"
                aria-describedby="keep-mounted-modal-description"
            >
                <Box sx={style}>
                    <Paper className={classes.paper} elevation={5}>
                        <Grid style={{ width: 300 }}>
                            <img
                                src={process.env.PUBLIC_URL + "/images/logo.png"}
                                alt="SGAGRI"
                                className={classes.logo}
                            />
                            {steps[activeStep].title}
                            {steps[activeStep].content}
                        </Grid>
                        <Stepper
                            variant="dots"
                            steps={maxSteps}
                            position="static"
                            activeStep={activeStep}
                        />
                    </Paper>
                </Box>
            </Modal>
        </div>
    );
}