import React from "react";
import { Button, makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import Autocomplete from "@material-ui/lab/Autocomplete";

const useStyles = makeStyles((theme) => ({
  root: {},
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
  linkChangeFilter: {
    marginTop: "50px",
  },
  formControl: {
    margin: theme.spacing(2),
    minWidth: "80%",
  },
  initialGrid: {
    marginBottom: 15,
  },
  margin: {
    marginTop: "15px",
  },
}));

export default function FiltroSimples(props) {
  const classes = useStyles();
  const onKeyPres = (e) => {
    if (e.key === "Enter") props.submit(e);
  };
  const valoresAtivo = [
    { value: true, label: "Sim" },
    { value: false, label: "Não" },
  ];

  return (
    <Grid item container xs={12} display="flex">
      <Grid
        item
        container
        xs={12}
        display="flex"
        className={classes.initialGrid}
      >
        <h4>Filtro avançado</h4>
      </Grid>
      {props.filtroNome && (
        <Grid item container xs={3} display="flex">
          <FormControl className={classes.formControl}>
            <TextField
              type="text"
              isplaceholderanimated={(false, "top")}
              label="Nome"
              className={classes.textField}
              id="nome"
              name="nome"
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(event, newValue) =>
                props.changeFiltroTextField("nome", event, newValue)
              }
              value={props.filtro.nome}
              defaultValue={props.filtro.nome}
              onKeyDown={(e) => onKeyPres(e)}
            />
          </FormControl>
        </Grid>
      )}
      {props.filtroCodigo && (
        <Grid item container xs={3} display="flex">
          <FormControl className={classes.formControl}>
            <TextField
              type="text"
              isplaceholderanimated={(false, "top")}
              label="Código"
              className={classes.textField}
              id="codigo"
              name="codigo"
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(event, newValue) =>
                props.changeFiltroTextField("codigo", event, newValue)
              }
              value={props.filtro.codigo}
              defaultValue={props.filtro.codigo}
              onKeyDown={(e) => onKeyPres(e)}
            />
          </FormControl>
        </Grid>
      )}
      {props.filtroAtivo && (
        <Grid item container xs={3} display="flex">
          <FormControl className={classes.formControl}>
            <Autocomplete
              id="ativo"
              size="small"
              options={valoresAtivo}
              getOptionLabel={(option) => option.label}
              onChange={(event, newValue) =>
                props.changeFiltroAtivo("ativo", event, newValue)
              }
              value={
                valoresAtivo.filter(
                  (x) =>
                    x.value ===
                    (props.filtro.ativo ? props.filtro.ativo.value : "")
                )[0]
              }
              defaultValue={
                valoresAtivo.filter(
                  (x) =>
                    x.value ===
                    (props.filtro.ativo ? props.filtro.ativo.value : "")
                )[0]
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Ativo"
                  placeholder="Selecione"
                />
              )}
            />
          </FormControl>
        </Grid>
      )}
      {props.filtroPeriodo && (
        <>
          <Grid item container xs={2} display="flex">
            <FormControl className={classes.formControl}>
              <TextField
                type="date"
                isplaceholderanimated={(false, "top")}
                label="De*: "
                className={classes.textField}
                id="periodoDe"
                name="periodoDe"
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(event, newValue) =>
                  props.changeFiltroTextField("periodoDe", event, newValue)
                }
                value={props.filtro.periodo}
                defaultValue={props.filtro.periodo}
              />
            </FormControl>
          </Grid>
          <Grid item container xs={2} display="flex">
            <FormControl className={classes.formControl}>
              <TextField
                type="date"
                isplaceholderanimated={(false, "top")}
                label="Até*: "
                className={classes.textField}
                id="periodoAte"
                name="periodoAte"
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(event, newValue) =>
                  props.changeFiltroTextField("periodoAte", event, newValue)
                }
                value={props.filtro.periodo}
                defaultValue={props.filtro.periodo}
              />
            </FormControl>
          </Grid>
        </>
      )}
      <Grid
        xs={12}
        container
        item
        direction="row"
        justify="flex-end"
        alignItems="center"
        className={classes.margin}
      >
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={(e) => props.submit(e)}
        >
          Buscar
        </Button>
      </Grid>
    </Grid>
  );
}
