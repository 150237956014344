import * as Yup from "yup";

export const cadastroUsuarioEsquema = Yup.object().shape({
  editar: Yup.boolean(),
  usuario: Yup.string().required("Campo obrigatório"),
  nomeCompleto: Yup.string().required("Campo obrigatório"),
  email: Yup.string()
    .email("Email precisa ser válido")
    .required("Campo obrigatório"),
  senha: Yup.string().when("editar", {
    is: false,
    then: Yup.string()
      .matches(
        /^(?=.*[A-Z])(?=.*[0-9])(?=.*[\W|_])(?=.{8,})/,
        "A senha deve conter ao menos 8 caracteres sendo, uma letra maiúscula, um número e um caracter especial"
      )
      .required("Campo obrigatório"),
  }),
  numeroContato: Yup.string().test(
    "validar-numero",
    "Dijite um número válido",
    function (value) {
      if (value.length > 0) {
        return value.length >= 13;
      }
      return true;
    }
  ),
  senhaConfirmar: Yup.string().when("editar", {
    is: false,
    then: Yup.string()
      .required("Campo obrigatório")
      .test("passwords-match", "Senhas precisam ser iguais", function (value) {
        return this.parent.senha === value;
      }),
  }),
});
