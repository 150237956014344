import React, { useState, useEffect } from "react";
import { useHistory } from 'react-router-dom'
import { Controller, useForm } from "react-hook-form";
import {
    TextField,
    Grid,
    Container,
    FormControl,
    Card,
    CardContent
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import usuarioService from "../../services/usuarioService";
import Alert from "@material-ui/lab/Alert";
import { usuarioSenhaEsquema } from "./usuarioSenha.validate";
import { yupResolver } from "@hookform/resolvers/yup";
import Page from "../../components/Page";
import Titulo from "../../components/Titulo";
import FooterBottons from "../../components/FooterBottons/FooterBottons"
import ToastHelper from '../../utils/toastHelper'
import { validatePermission } from "../../permissoes/permissionHelper"
import { PERMISSAO } from "../../permissoes/permissoes"
import { Store } from "../../store"
import watch from "redux-watch"

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3),
    },
    margin: {
        margin: theme.spacing(1),
    },
    card: {
        marginTop: '15px',
    },
    formControl: {
        margin: theme.spacing(2),
        paddingRight: "15px",
        minWidth: '100%',
    },
    errors: {
        color: 'red',
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justify: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    boxLoading: {
        paddingLeft: '45%',
    },
    alert: {
        width: '100%'
    }
}));

export default function SenhaUsuario(props) {
    const classes = useStyles();
    let history = useHistory()

    const {
        handleSubmit,
        control,
        formState: { errors },
    } = useForm({
        defaultValues: {
            senhaAntiga: "",
            senhaNova: "",
            senhaNovaConfirmar: "",
        },
        resolver: yupResolver(usuarioSenhaEsquema),
    });

    const [mensagemErroSenha, setMensagemErroSenha] = useState("");

    const {
        match: { params },
    } = props;


    const onSubmit = async (dados) => {
        var alterarSenha = {
            id: params.id ? params.id : null,
            senhaAntiga: dados.senhaAntiga,
            senhaNova: dados.senhaNova
        }

        usuarioService.alterarSenha(alterarSenha)
            .then((result) => {
                ToastHelper.success('Senha atualizada com sucesso!')
                history.goBack()
            })
            .catch((error) => {
                setMensagemErroSenha(error.response.data);
            });
    };

    useEffect(() => {
        const w = watch(Store.getState, "permissao.permissoes")
        Store.subscribe(
            w((newVal, oldVal, objectPath) => {
                if (!validatePermission(PERMISSAO.MODULO_USUARIO_ATUALIZAR, newVal)) {
                    history.push("/")
                }
            })
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Page className={classes.root} title="Usuário">
            <div className={classes.root}>
                <Container maxWidth={"xl"}>
                    <Titulo titulo={"Alterar Senha"} />
                    <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
                        <Card className={classes.card}>
                            <CardContent>
                                <Grid container item xs={12} display="flex">
                                    {mensagemErroSenha && (
                                        <Alert
                                            severity="error"
                                            onClose={() => {
                                                setMensagemErroSenha("");
                                            }}
                                            className={classes.alert}
                                        >
                                            {mensagemErroSenha}
                                        </Alert>
                                    )}
                                    <FormControl className={classes.formControl}>
                                        <Controller
                                            control={control}
                                            id="senhaAntiga"
                                            name="senhaAntiga"
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    variant="standard"
                                                    margin="normal"
                                                    fullWidth
                                                    label="Senha Antiga*"
                                                    autoComplete="senhaAntiga"
                                                    autoFocus
                                                    type="password"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            )}
                                        />
                                        <p className={classes.errors}>
                                            {errors.senhaAntiga ? errors.senhaAntiga.message : ""}
                                        </p>
                                    </FormControl>
                                </Grid>

                                <Grid container item xs={12} display="flex">
                                    <FormControl className={classes.formControl}>
                                        <Controller
                                            control={control}
                                            id="senhaNova"
                                            name="senhaNova"
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    variant="standard"
                                                    margin="normal"
                                                    fullWidth
                                                    label="Senha Nova*"
                                                    autoComplete="senhaNova"
                                                    autoFocus
                                                    type="password"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            )}
                                        />
                                        <p className={classes.errors}>
                                            {errors.senhaNova ? errors.senhaNova.message : ""}
                                        </p>
                                    </FormControl>
                                </Grid>
                                <Grid container item xs={12} display="flex">
                                    <FormControl className={classes.formControl}>
                                        <Controller
                                            control={control}
                                            id="senhaNovaConfirmar"
                                            name="senhaNovaConfirmar"
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    variant="standard"
                                                    margin="normal"
                                                    fullWidth
                                                    label="Confirmar Nova Senha*"
                                                    autoComplete="senhaNovaConfirmar"
                                                    autoFocus
                                                    type="password"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            )}
                                        />
                                        <p className={classes.errors}>
                                            {errors.senhaNovaConfirmar ? errors.senhaNovaConfirmar.message : ""}
                                        </p>
                                    </FormControl>
                                </Grid>
                            </CardContent>
                        </Card>
                        <FooterBottons />
                    </form>
                </Container>
            </div>
        </Page>
    );
}
