import React, { useEffect, useState } from "react";
import watch from "redux-watch";
import { makeStyles } from "@material-ui/core/styles";
import moduloService from "../../services/moduloService";
import fazendaService from "../../services/fazendaService";
import AppCards from "../../components/AppCards";
import { Store } from "../../store";
import Loading from "../../components/Loading";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { STORE_FAZENDA } from "../../store/actions/fazenda";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(5),
  },
}));

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function Home(props) {
  const classes = useStyles();
  const [modulos, setModulos] = useState([]);
  const [openLoading, setOpenLoading] = useState(true);
  const TOKEN_KEY = "@projeto-token";
  const query = useQuery();
  const dispatch = useDispatch();

  useEffect(() => {
    async function init() {
      const fazendaId = query.get("fazendaId");
      let fazenda = null;
      if (fazendaId) {
        setOpenLoading(true);
        await fazendaService
          .getById(fazendaId)
          .then((resp) => {
            fazenda = resp.data;
          })
          .catch(() => {
            fazenda = JSON.parse(localStorage.getItem("fazenda"));
          })
          .finally(() => setOpenLoading(false));
      } else {
        fazenda = JSON.parse(localStorage.getItem("fazenda"));
      }

      const w = watch(Store.getState, "fazenda.fazendaSelecionada");
      Store.subscribe(
        w((newVal, oldVal, objectPath) => {
          getModulos(newVal);
        })
      );

      localStorage.setItem("fazenda", JSON.stringify(fazenda));
      dispatch({
        type: STORE_FAZENDA,
        fazenda: fazenda,
      });
    }
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const acessarModulo = (url) => {
    let token = localStorage.getItem(TOKEN_KEY);
    const fazenda = JSON.parse(localStorage.getItem("fazenda"));
    token = token.replace("Bearer ", "");
    const path = `${url}/auth-callback?token=${token}&fazendaId=${fazenda.id}&portal=${window.location.origin}`;
    window.location = path;
  };

  const getModulos = (fazenda) => {
    setOpenLoading(true);
    if (fazenda) {
      moduloService
        .getAll(fazenda.id)
        .then((resp) => {
          setModulos(resp.data);
        })
        .finally(() => setOpenLoading(false));
    } else {
      setModulos([]);
      setOpenLoading(false);
    }
  };

  return (
    <div className={classes.root}>
      <AppCards modulos={modulos} acessarModulo={acessarModulo} />
      <Loading open={openLoading} texto="Carregando..." />
    </div>
  );
}
