import api from "./portalAPI";

const service = {
  buscarTudo(param) {
    return api.get(`/Perfil${param}`);
  },
  buscarPorId(grupoId) {
    return api.get(`/Perfil/${grupoId}`);
  },
  cadastrar(param) {
    return api.post(`/Perfil`, param);
  },
  atualizar(param) {
    return api.put(`/Perfil`, param);
  },
  ativarDesativar(id) {
    return api.get(`/Perfil/AtivaDesativa/${id}`);
  },
  deletar(id) {
    return api.delete(`/Perfil/${id}`);
  },
  buscarFuncionalidadesPermissoes() {
    return api.get(`/Permissao/FuncionalidadesPermissoes/BuscarTodas`);
  },
  buscarTodosPerfis() {
    return api.get(`/Perfil/BuscarPerfis`);
  },
};

export default service;
