import React from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import BlockIcon from "@material-ui/icons/Block";
import { Tooltip } from "@material-ui/core";
import { red } from "@material-ui/core/colors";

const useStyles = makeStyles({
  habilitado: {},
  desabilitado: {
    opacity: 0.5,
  },
  media: {
    height: 140,
  },
  title: {
    height: 30,
  },
  description: {
    height: 60,
  },
  actions: {
    height: 40,
    display: "flex",
    justifyContent: "flex-end",
  },
});

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}))(Tooltip);

export default function AppCard(props) {
  const classes = useStyles();

  const renderCard = () => {
    return (
      <Card className={props.ativo ? classes.habilitado : classes.desabilitado}>
        <CardActionArea
          onClick={() => props.acessarModulo(props.url)}
          disabled={!props.ativo}
        >
          <CardMedia
            className={classes.media}
            image={props.cover}
            title={props.title}
          />
          <CardContent>
            <Typography
              className={classes.title}
              gutterBottom
              variant="h5"
              component="h2"
            >
              {props.title}
            </Typography>
            <Typography
              className={classes.description}
              variant="body2"
              color="textSecondary"
              component="p"
            >
              {props.description}
            </Typography>
          </CardContent>
        </CardActionArea>
        <CardActions className={classes.actions}>
          {props.ativo ? (
            <Button
              size="small"
              color="primary"
              onClick={() => props.acessarModulo(props.url)}
            >
              Acessar
            </Button>
          ) : (
            <BlockIcon style={{ color: red[500] }} />
          )}
        </CardActions>
      </Card>
    );
  };

  return props.ativo ? (
    renderCard()
  ) : (
    <HtmlTooltip
      title={
        <React.Fragment>
          <Typography color="inherit">Módulo não contratado</Typography>
          {
            "Entre em contato com o suporte para mais informações sobre esse módulo"
          }
        </React.Fragment>
      }
    >
      {renderCard()}
    </HtmlTooltip>
  );
}
