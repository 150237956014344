import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { getUsername } from "../services/auth";
import Image from "../assets/campos.jpg";
import watch from "redux-watch";
import { Store } from "../store";

const useStyles = makeStyles({
  root: {
    width: "500px",
    height: "350px",
  },
  media: {
    height: 140,
  },
});

export default function BemVindo() {
  const classes = useStyles();

  const sair = () => {
    localStorage.clear();
    window.location.href = "logout";
  };

  const w = watch(Store.getState, "fazenda.fazendaSelecionada");
  Store.subscribe(
    w((newVal, oldVal, objectPath) => {
      window.location = '/';
    })
  );

  return (
    <Card className={classes.root}>
      <CardActionArea>
        <CardMedia
          className={classes.media}
          image={Image}
          title="Contemplative Reptile"
        />
        <CardContent>
          <Typography gutterBottom variant="h1" component="h1">
            {getUsername()
              ? `Bem vindo, ${getUsername().split(" ")[0]}!`
              : `Bem vindo!`}
          </Typography>
          <Typography variant="h4" color="textSecondary" component="p">
            Para iniciar o sistema é necessário selecionar um cliente no menu
            superior.
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <Button size="small" color="primary" onClick={sair}>
          Sair
        </Button>
      </CardActions>
    </Card>
  );
}
