import React from "react";
import { useHistory } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import { Button, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  card: {
    marginTop: "15px",
  },
  formControl: {
    margin: theme.spacing(2),
    minWidth: "80%",
  },
}));

export default function FooterBottons(props) {
  const classes = useStyles();
  let history = useHistory();
  let botaoVoltar;
  if (
    typeof props.externo !== "undefined" &&
    typeof props.externo == "function"
  ) {
    botaoVoltar = (
      <Button
        size="small"
        variant="contained"
        color="default"
        onClick={() => {
          props.externo();
        }}
      >
        Cancelar
      </Button>
    );
  } else {
    botaoVoltar = (
      <Button
        size="small"
        variant="contained"
        color="default"
        onClick={() => {
          props.rotaVoltar ? history.push(props.rotaVoltar) : history.goBack();
        }}
      >
        Voltar
      </Button>
    );
  }

  return (
    <Grid container item xs={12} display="flex" className={classes.card}>
      <Grid xs={6} container item direction="row" alignItems="center">
        {botaoVoltar}
      </Grid>
      <Grid
        xs={6}
        container
        item
        direction="row"
        justify="flex-end"
        alignItems="center"
      >
        {!props.desabilitaSubmit && (
          <Button
            variant="contained"
            color="primary"
            type="submit"
            size="small"
          >
            Salvar
          </Button>
        )}
      </Grid>
    </Grid>
  );
}
