import CadastroUsuario from "../pages/usuario/usuarioCadastro";
import Usuario from "../pages/usuario/index";
import SenhaUsuario from "../pages/usuario/usuarioSenha";

export const UsuarioRoute = [
  {
    path: "/usuario/listagem",
    key: "USUARIO_LISTAGEM",
    exact: true,
    component: Usuario,
  },
  {
    path: "/usuario/cadastro",
    key: "USUARIO_CADASTRO",
    exact: true,
    component: CadastroUsuario,
  },
  {
    path: "/usuario/senha/:id([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})",
    key: "USUARIO_EDICAO_SENHA",
    exact: true,
    component: SenhaUsuario,
  },
  {
    path: "/usuario/:modo(editar|visualizar)/:id([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})",
    key: "USUARIO_VISUALIZAR_EDICAO",
    exact: true,
    component: CadastroUsuario,
  },
];
