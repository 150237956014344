import api from "./portalAPI";

const service = {
  forget(email) {
    return api.post(`/ForgetLogin`, { email });
  },
  validarLinkRecuperarAcesso(token) {
    return api.get(`/ForgetLogin/ValidarLinkRecuperarAcesso/${token}`);
  },
};

export default service;
