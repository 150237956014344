import React, { useState, useEffect } from "react";
import { Box, makeStyles } from "@material-ui/core";
import FiltroSimples from "../../components/ToolBar/FiltroSimples";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Grid from "@material-ui/core/Grid";
import { Divider, Button } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import Autocomplete from "@material-ui/lab/Autocomplete";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: "90%",
  },
  divider: {
    margin: theme.spacing(1),
    marginTop: "10px",
    marginBottom: "10px",
  },
}));

const ToolbarAssociacaoUsuarioModulo = ({
  handleFiltro,
  filtroInicial,
  className,
  ...props
}) => {
  const classes = useStyles();
  const [filtro, setFiltro] = useState(filtroInicial);
  const [filtroExpanded, setFiltroExpanded] = useState(filtro.filtroExpanded);

  useEffect(() => {
    setFiltro(filtroInicial);
  }, [filtroInicial]);

  const setStateAcocordion = () => {
    let newFiltro = { ...filtro };
    newFiltro.filtroExpanded = !filtroExpanded;
    setFiltro(newFiltro);
    setFiltroExpanded(!filtroExpanded);
  };

  const changeFiltro = (campo, event) => {
    let newFiltro = { ...filtro };
    newFiltro[campo] = event.target.value;
    setFiltro(newFiltro);
  };

  const submit = (event) => {
    handleFiltro(filtro);
  };

  return (
    <Box mt={3}>
      <Accordion expanded={filtroExpanded} onChange={(e) => setStateAcocordion}>
        <AccordionSummary
          expandIcon={!props.temFiltroAvancado ? "" : <ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
          style={{
            backgroundColor: "#FFFFFF",
          }}
          onClick={() => setStateAcocordion(!filtroExpanded)}
        >
          <FiltroSimples
            filtro={filtro}
            changeFiltro={changeFiltro}
            submit={submit}
            temFiltroAvancado={props.temFiltroAvancado}
            clickadd={props.clickadd}
          />
        </AccordionSummary>
        {props.temFiltroAvancado && (
          <AccordionDetails>
            <Grid container item xs={12} display="flex">
              <Grid container item xs={12} display="flex">
                <Grid container item xs={4} display="flex">
                  <FormControl className={classes.formControl}>
                    <Autocomplete
                      freeSolo
                      options={props.opcoesUsuario}
                      getOptionLabel={(option) => option?.usuario}
                      onChange={(event, option) => {
                        setFiltro({
                          ...filtro,
                          usuarioId: option ? option.id : null,
                          usuario: option,
                          usuarioNome: option ? option.usuario : null,
                        });
                      }}
                      onInputChange={(event, value) => {
                        setFiltro({ ...filtro, usuarioNome: value });
                        props.buscarUsuarios(event, value);
                      }}
                      inputValue={filtro.usuarioNome}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          label={"Usuário"}
                          placeholder="Selecione"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid container item xs={4} display="flex">
                  <FormControl className={classes.formControl}>
                    <Autocomplete
                      options={props.opcoesPerfil}
                      getOptionLabel={(option) => option.nome}
                      onChange={(event, option) => {
                        setFiltro({
                          ...filtro,
                          perfilId: option ? option.id : null,
                          perfil: option,
                        });
                      }}
                      value={filtro.perfil}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          label={"Perfil"}
                          placeholder="Selecione"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          value={filtro.perfil}
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid container item xs={4} display="flex">
                  <FormControl className={classes.formControl}>
                    <Autocomplete
                      options={props.opcoesCliente}
                      getOptionLabel={(option) => option.nomeRazao}
                      onChange={(event, option) => {
                        setFiltro({
                          ...filtro,
                          fazendaId: option ? option.id : null,
                          fazenda: option,
                        });
                      }}
                      getOptionSelected={(option, value) =>
                        option?.id === value?.id
                      }
                      value={filtro.fazenda}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          label={"Cliente"}
                          placeholder="Selecione"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container item xs={12} display="flex">
                <Divider className={classes.divider} />
                <Grid
                  xs={12}
                  container
                  item
                  direction="row"
                  justify="flex-end"
                  alignItems="center"
                  className={classes.margin}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={(e) => submit(e)}
                  >
                    Buscar
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </AccordionDetails>
        )}
      </Accordion>
    </Box>
  );
};

export default ToolbarAssociacaoUsuarioModulo;
