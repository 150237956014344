export const dataDuplicar = [
  {
    label: "Unidades de medida",
    checked: false,
    nome: "unidade",
    dependentes: [
      "insumos",
      "insumosCompostos",
      "recursos",
      "templatePlanejamento",
      "cultura",
      "parametros",
    ],
  },
  {
    label: "Culturas",
    checked: false,
    nome: "cultura",
    dependentes: ["parametros", "templatePlanejamento"],
    obrigatorios: ["unidade"],
  },
  {
    label: "Grupos de serviço",
    checked: false,
    nome: "gruposServicos",
    dependentes: ["servico", "templatePlanejamento"],
  },
  {
    label: "Serviços",
    checked: false,
    nome: "servico",
    dependentes: ["templatePlanejamento", "templatePlanejamento"],
    obrigatorios: ["gruposServicos"],
  },
  {
    label: "Grupos de Produto",
    checked: false,
    nome: "gruposProdutos",
    dependentes: [
      "insumosCompostos",
      "insumos",
      "recursos",
      "templatePlanejamento",
    ],
  },
  {
    label: "Insumos",
    checked: false,
    nome: "insumos",
    dependentes: ["insumosCompostos", "recursos", "templatePlanejamento"],
    obrigatorios: [
      "unidade",
      "gruposProdutos",
      "recursos",
      "maquinaTipo",
      "pessoaTipo",
      "insumosCompostos",
    ],
  },
  {
    label: "Insumos Compostos",
    checked: false,
    nome: "insumosCompostos",
    dependentes: ["templatePlanejamento", "insumos", "recursos"],
    obrigatorios: [
      "unidade",
      "gruposProdutos",
      "recursos",
      "maquinaTipo",
      "pessoaTipo",
      "insumosCompostos",
      "insumos",
    ],
  },
  {
    label: "Tipos de máquina",
    checked: false,
    dependentes: [
      "templatePlanejamento",
      "insumos",
      "recursos",
      "insumosCompostos",
    ],
    nome: "maquinaTipo",
  },
  {
    label: "Grupos de colaboradores",
    checked: false,
    dependentes: [
      "templatePlanejamento",
      "insumos",
      "recursos",
      "insumosCompostos",
    ],
    nome: "pessoaTipo",
  },
  {
    label: "Recursos de mão de obra e máquina",
    checked: false,
    nome: "recursos",
    dependentes: [
      "insumosCompostos",
      "insumos",
      "templatePlanejamento",
      "insumos",
      "recursos",
    ],
    obrigatorios: [
      "unidade",
      "insumos",
      "gruposProdutos",
      "pessoaTipo",
      "maquinaTipo",
      "insumosCompostos",
    ],
  },
  {
    label: "Idade da lavoura",
    checked: false,
    nome: "parametros",
    dependentes: ["templatePlanejamento"],
    obrigatorios: ["cultura", "unidade"],
  },
  {
    label: "Modelos de manejo",
    checked: false,
    nome: "templatePlanejamento",
    obrigatorios: [
      "unidade",
      "servico",
      "gruposServicos",
      "cultura",
      "insumos",
      "gruposProdutos",
      "insumosCompostos",
      "recursos",
      "pessoaTipo",
      "maquinaTipo",
      "parametros",
    ],
  },
  {
    label: "Contas Gerenciais",
    checked: false,
    nome: "contaGerencial"
  },
  {
    label: "Tipos de Patrimônio",
    checked: false,
    nome: "tipoPatrimonio"
  },
  {
    label: "Tipo de Documento",
    checked: false,
    nome: "tipoDocumento"
  },
  {
    label: "Forma de Pagamento",
    checked: false,
    nome: "tipoPagamentoRecebimento"
  },
  {
    label: "Campos da Parcela",
    checked: false,
    nome: "camposParcela",
    obrigatorios: ["contaGerencial"]
  }
];
