import Cliente from "../pages/cliente";
import ClienteCadastro from "../pages/cliente/ClienteCadastro";

export const ClienteRoute = [
  {
    path: "/cliente/listagem",
    key: "CLIENTE_LISTAGEM",
    exact: true,
    component: Cliente,
  },
  {
    path: "/cliente/cadastro",
    key: "CLIENTE_CADASTRO",
    exact: true,
    component: ClienteCadastro,
  },
  {
    path: "/cliente/:modo(editar|visualizar)/:id([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})",
    key: "CLIENTE_EDITAR_VISUALIZAR",
    exact: true,
    component: ClienteCadastro,
  },
];
