export default class ObjectHelper {
    static createParams = async (data) => {
        let url = await Object.keys(data)
            .map(function (k) {
                return data[k] !== null && data[k] !== undefined
                    ? encodeURIComponent(k) + '=' + encodeURIComponent(data[k])
                    : ''
            })
            .join('&')

        url = new URLSearchParams(url).toString()
        url = await url.replace('texto', 'filtro')
        return '?' + url
    }
}