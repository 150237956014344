import api from "./portalAPI";

const service = {
  getAll(fazendaId) {
    return api.get(`/Modulo/${fazendaId}`);
  },
  buscarTudo() {
    return api.get(`/Modulo/buscarTudo`);
  }
};

export default service;
