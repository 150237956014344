import api from "./portalAPI";

const service = {
    buscarTudo(param) {
        return api.get(`/UsuarioModuloFazenda${param}`)
    },
    ativarDesativar(id) {
        return api.put(`/UsuarioModuloFazenda/AtivarDesativar`, id);
    },
    deletar(id) {
        return api.delete(`/UsuarioModuloFazenda/${id}`);
    },
    cadastro(usuarioModuloFazenda) {
        return api.post(`/UsuarioModuloFazenda`, usuarioModuloFazenda);
    },
    buscarUsuarioModuloCliente(id) {
        return api.get(`/UsuarioModuloFazenda/${id}`);
    },
    atualizar(usuarioModuloFazenda) {
        return api.put(`/UsuarioModuloFazenda`, usuarioModuloFazenda);
    },
};

export default service;
