import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Box, Container, makeStyles } from "@material-ui/core";
import Titulo from "../../components/Titulo/index";
import Page from "../../components/Page";
import Toolbar from "../../../src/components/BasicPageTopBar/Toolbar";
import PerfilListagem from "./PerfilListagem";
import ToastHelper from "../../utils/toastHelper";
import ObjectHelper from "../../utils/objetcHelper";
import PerfilService from "../../services/PerfilService";
import { validatePermission } from "../../permissoes/permissionHelper";
import { PERMISSAO } from "../../permissoes/permissoes";
import { connect } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

function Perfil(props) {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const [perfis, setPerfis] = useState([]);
  const [filtro, setFiltro] = useState(
    useSelector((state) => state.perfil.filtroListagem)
  );

  useEffect(() => {
    buscarPerfis(filtro);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const alteraFiltro = async (filtro) => {
    await dispatch({
      type: "SET_FILTRO_LISTAGEM_PERFIL",
      filtroListagem: filtro,
    });
    await setFiltro(filtro);
  };

  const handleFiltro = async (filtro) => {
    await dispatch({
      type: "SET_FILTRO_LISTAGEM_PERFIL",
      filtroListagem: filtro,
    });
    await buscarPerfis(filtro);
    await setFiltro(filtro);
  };

  const buscarPerfis = async (filtro) => {
    setLoading(true);
    let novoFiltro = { ...filtro };
    novoFiltro.descedescending = false;
    novoFiltro.order = "Nome";
    novoFiltro.pageSize = 100;

    let params = await ObjectHelper.createParams(novoFiltro);

    PerfilService.buscarTudo(params)
      .then((response) => {
        if (response.status === 200) {
          let newFiltro = { ...filtro };
          newFiltro.totalItens = response.data.totalItens;
          newFiltro.totalPaginas = response.data.totalPaginas;
          newFiltro.pageSize = novoFiltro.pageSize;
          newFiltro.page = filtro.page ? filtro.page : 1;
          newFiltro.order = filtro.order;

          alteraFiltro(newFiltro);
          setPerfis(response.data.itens);
          setLoading(false);
        } else {
          ToastHelper.error(response.statusText);
          setLoading(false);
        }
      })
      .catch((error) => {
        ToastHelper.error("Erro ao tentar buscar perfis");
        setLoading(false);
      });
  };

  const onChangePage = async (page) => {
    let novoFiltro = { ...filtro };
    novoFiltro.page = page;
    await alteraFiltro(novoFiltro);
    buscarPerfis(novoFiltro);
  };

  const cadastrar = (item) => {
    props.history.push({
      pathname: `/perfil/cadastro`,
    });
  };

  const visualizar = async (item) => {
    history.push(`/perfil/visualizar/${item.id}`);
  };

  const editar = (itemId) => {
    history.push(`/perfil/editar/${itemId}`);
  };

  const ativarDesativar = async (itemId) => {
    setLoading(true);
    await PerfilService.ativarDesativar(itemId).then((response) => {
      if (response.status === 200) {
        let novoFiltro = { ...filtro };
        buscarPerfis(novoFiltro);
      } else {
        ToastHelper.error(response.statusText);
        setLoading(false);
      }
    });
  };

  const deletar = async (item) => {
    await PerfilService.deletar(item.id)
      .then((response) => {
        if (response.status === 200) {
          ToastHelper.success("Perfil excluído com sucesso!");
          let novoFiltro = { ...filtro };
          buscarPerfis(novoFiltro);
        } else {
          ToastHelper.error(response.statusText);
        }
      })
      .catch((e) => ToastHelper.error(e.response.data))
      .finally(() => setLoading(false));
  };

  const mudaOrdenacao = (numeroColuna, orientacao, colunas) => {
    let novoFiltro = { ...filtro };
    novoFiltro.descedescending = !novoFiltro.descedescending;
    if (colunas[numeroColuna]?.field === "nome") {
      novoFiltro.order = "Nome";
    } else {
      novoFiltro.order = colunas[numeroColuna]?.field;
    }
    alteraFiltro(novoFiltro);
    buscarPerfis(novoFiltro);
  };

  return (
    <Page className={classes.root} title="Perfis">
      <Container maxWidth={"xl"}>
        <Titulo titulo="Perfis" />
        <Toolbar
          handleFiltro={handleFiltro}
          filtroInicial={filtro}
          clickadd={cadastrar}
          temFiltroAvancado={false}
          disabled={
            !validatePermission(PERMISSAO.MODULO_PERFIL_CRIAR, props.permissoes)
          }
        />
        <Box mt={3}>
          <PerfilListagem
            data={perfis}
            onChangePage={(page) => onChangePage(page)}
            filtro={filtro}
            isLoading={loading}
            pageSize={filtro.pageSize}
            editar={editar}
            editarPermissao={validatePermission(
              PERMISSAO.MODULO_PERFIL_ATUALIZAR,
              props.permissoes
            )}
            ativarDesativar={ativarDesativar}
            deletar={deletar}
            deletarPermissao={validatePermission(
              PERMISSAO.MODULO_PERFIL_EXCLUIR,
              props.permissoes
            )}
            visualizar={visualizar}
            visualizarPermissao={validatePermission(
              PERMISSAO.MODULO_PERFIL_LEITURA,
              props.permissoes
            )}
            mudaOrdenacao={mudaOrdenacao}
          />
        </Box>
      </Container>
    </Page>
  );
}

const mapStateToProps = (state) => {
  return {
    permissoes: state.permissao.permissoes,
  };
};

export default connect(mapStateToProps)(Perfil);
