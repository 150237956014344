import React from "react";
import Table from "../../components/Table/index";
import { colors } from "@material-ui/core";
import EditCompleteIcon from "@material-ui/icons/EditOutlined";
import LigaDesliga from "@material-ui/icons/PowerSettingsNew";
import IconeAtivo from "@material-ui/icons/CheckCircle";
import IconeInativo from "@material-ui/icons/Cancel";
import VisulizarIcon from "@material-ui/icons/SearchOutlined";

export default function ClienteListagem(props) {
  const colunas = [
    {
      title: "Nome",
      field: "nomeRazao",
      editable: "never",
      defaultSort: "asc",
      customSort: (a, b) => a.nomeRazao - b.nomeRazao,
    },
    {
      title: "Cep",
      field: "cep",
      editable: "never",
      sorting: false,
    },
    {
      title: "Área(Ha)",
      field: "hectares",
      editable: "never",
      defaultSort: "asc",
      customSort: (a, b) => a.hectares - b.hectares,
    },
    {
      title: "Situação",
      field: "ativo",
      editable: "never",
      render: (rowData) =>
        rowData.ativo ? (
          <IconeAtivo style={{ color: colors.green[800] }} />
        ) : (
          <IconeInativo style={{ color: colors.red[500] }} />
        ),
      defaultSort: "asc",
    },
  ];

  return (
    <>
      <Table
        title={"Listagem"}
        columns={colunas}
        data={props.data}
        onChangePage={(page) => props.onChangePage(page)}
        page={props.filtro.page}
        totalCount={props.filtro.totalItens}
        isLoading={props.isLoading}
        pageSize={props.pageSize}
        onOrderChange={(numeroColuna, orientacao) => {
          props.mudaOrdenacao(numeroColuna, orientacao, colunas);
        }}
        emptyRowsWhenPaging={false}
        editarCelula={props.editarCelula ? props.editarCelula : null}
        actions={[
          {
            icon: LigaDesliga,
            tooltip: "Ativar / Desativar",
            onClick: (event, rowData) => props.ativarDesativar(rowData.id),
            disabled: !props.editarPermissao,
          },
          {
            icon: EditCompleteIcon,
            tooltip: "Edição",
            onClick: (event, rowData) => props.editar(rowData.id),
            disabled: !props.editarPermissao,
          },
          {
            icon: VisulizarIcon,
            tooltip: "Visualizar",
            onClick: (event, rowData) => props.visualizar(rowData),
            disabled: !props.visualizarPermissao,
          },
        ]}
      />
    </>
  );
}
