import api from "./portalAPI";

const service = {
  getAll() {
    return api.get(`/Fazenda`);
  },
  getById(id) {
    return api.get(`/Fazenda/${id}`);
  },
};

export default service;
