import React from "react";
import "./Main.css";
import { RenderRoutes } from "./routes/RenderRoutes";
import { ThemeProvider } from "@material-ui/core";
import theme from "./shared/mainThemeCss/index";
import { createBrowserHistory } from "history";
import { routes } from "./routes";
import { Router, Switch, Route } from "react-router-dom";
import Login from "./pages/login/Login";
import { Logout } from "./logout";
import MainLayout from "./layouts/MainLayout";
import ToastHelper from "../src/utils/toastHelper";
import RedefinirSenhaUsuarioLogin from "./pages/redefinirSenhaLogin/redefinirSenhaLogin";
import RecuperarAcesso from "./pages/recuperarAcesso/recuperarAcesso";
import RecuperarAcessoMobile from "./pages/login/mobile/recuperarAcesso";

function Main() {
  const history = createBrowserHistory();
  ToastHelper.create();

  return (
    <ThemeProvider theme={theme}>
      <Router history={history}>
        <Switch>
          <Route path="/login" component={Login} exact />
          <Route path="/logout" component={Logout} exact />
          <Route
            path="/redefinirSenhaLogin/:id"
            component={RedefinirSenhaUsuarioLogin}
            exact
          />
          <Route path="/recuperarAcesso/:id" component={RecuperarAcesso} exact/>
          <Route path="/app/recuperarAcesso/:token" component={RecuperarAcessoMobile} exact/>
          <Route>
            <MainLayout>
              <RenderRoutes routes={routes} />
            </MainLayout>
          </Route>
        </Switch>
      </Router>
    </ThemeProvider>
  );
}

export default Main;
