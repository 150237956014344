export const PERMISSAO = {
  MODULO_USUARIO_EXCLUIR: "MODULO_USUARIO.EXCLUIR",
  MODULO_USUARIO_ATUALIZAR: "MODULO_USUARIO.ATUALIZAR",
  MODULO_USUARIO_CRIAR: "MODULO_USUARIO.CRIAR",
  MODULO_USUARIO_LEITURA: "MODULO_USUARIO.LEITURA",

  MODULO_PERFIL_LEITURA: "MODULO_PERFIL.LEITURA",
  MODULO_PERFIL_EXCLUIR: "MODULO_PERFIL.EXCLUIR",
  MODULO_PERFIL_ATUALIZAR: "MODULO_PERFIL.ATUALIZAR",
  MODULO_PERFIL_CRIAR: "MODULO_PERFIL.CRIAR",

  MODULO_USUARIO_MODULO_FAZENDA_LEITURA:
    "MODULO_USUARIO_MODULO_FAZENDA.LEITURA",
  MODULO_USUARIO_MODULO_FAZENDA_EXCLUIR:
    "MODULO_USUARIO_MODULO_FAZENDA.EXCLUIR",
  MODULO_USUARIO_MODULO_FAZENDA_ATUALIZAR:
    "MODULO_USUARIO_MODULO_FAZENDA.ATUALIZAR",
  MODULO_USUARIO_MODULO_FAZENDA_CRIAR: "MODULO_USUARIO_MODULO_FAZENDA.CRIAR",

  MODULO_USUARIO_PERFIL_FAZENDA_LEITURA:
    "MODULO_USUARIO_PERFIL_FAZENDA.LEITURA",
  MODULO_USUARIO_PERFIL_FAZENDA_EXCLUIR:
    "MODULO_USUARIO_PERFIL_FAZENDA.EXCLUIR",
  MODULO_USUARIO_PERFIL_FAZENDA_ATUALIZAR:
    "MODULO_USUARIO_PERFIL_FAZENDA.ATUALIZAR",
  MODULO_USUARIO_PERFIL_FAZENDA_CRIAR: "MODULO_USUARIO_PERFIL_FAZENDA.CRIAR",

  MODULO_CLIENTE_LEITURA: "MODULO_CLIENTE.LEITURA",
  MODULO_CLIENTE_ATUALIZAR: "MODULO_CLIENTE.ATUALIZAR",
  MODULO_CLIENTE_CRIAR: "MODULO_CLIENTE.CRIAR",
};
