import {PerfilCadastro} from '../pages/perfil/PerfilCadastro';
import Perfil from '../pages/perfil/index';

export const PerfilRoute = [
    {
        path: '/perfil/listagem',
        key: 'PERFIL_LISTAGEM',
        exact: true,
        component: Perfil,
    },
    {
        path: '/perfil/cadastro',
        key: 'PERFIL_CADASTRO',
        exact: true,
        component: PerfilCadastro,
    },
    {
        path: '/perfil/:modo(editar|visualizar)/:id([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})',
        key: 'PERFIL_EDITAR_VISUALIZAR',
        exact: true,
        component: PerfilCadastro,
    },
];
