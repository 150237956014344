import api from "./portalAPI";

const service = {
    buscarTudo(param) {
        return api.get(`/UsuarioPerfilFazenda${param}`)
    },
    ativarDesativar(id) {
        return api.put(`/UsuarioPerfilFazenda/AtivarDesativar`, id);
    },
    deletar(id) {
        return api.delete(`/UsuarioPerfilFazenda/${id}`);
    },
    cadastro(usuarioPerfilFazenda) {
        return api.post(`/UsuarioPerfilFazenda`, usuarioPerfilFazenda);
    },
    buscarUsuarioPerfilCliente(id) {
        return api.get(`/UsuarioPerfilFazenda/${id}`);
    },
    atualizar(usuarioPerfilFazenda) {
        return api.put(`/UsuarioPerfilFazenda`, usuarioPerfilFazenda);
    },
};

export default service;
