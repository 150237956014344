import { SET_FILTRO_LISTAGEM_USUARIO_MODULO_FAZENDA } from '../actions/usuarioModuloFazenda'

export const storageUsuarioModuloFazenda = 'usuarioModuloFazenda'

const initialState = {
    filtroListagem: {
        pageSize: 100,
        page: 1,
        filtro:'',
        order: 'usuario',
        filtroExpanded: false
    },
}

export const usuarioModuloFazenda = (state = initialState, action) => {
    switch (action.type) {
        case SET_FILTRO_LISTAGEM_USUARIO_MODULO_FAZENDA:
            return {
                ...state,
                filtroListagem: action.filtroListagem,
            }
        default:
            return state
    }
}
