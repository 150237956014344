import React, { useState, useEffect } from 'react'
import { Box } from '@material-ui/core'
import FiltroSimples from '../../components/ToolBar/FiltroSimples'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'



const UsuarioToolBar = ({
    handleFiltro,
    filtroInicial,
    className,
    ...props
}) => {
    const [filtro, setFiltro] = useState(filtroInicial)
    const [filtroExpanded, setFiltroExpanded] = useState(filtro.filtroExpanded)

    useEffect(() => {
        setFiltro(filtroInicial)
    }, [filtroInicial])

    const setStateAcocordion = () => {
        let newFiltro = { ...filtro }
        newFiltro.filtroExpanded = !filtroExpanded
        setFiltro(newFiltro)
        setFiltroExpanded(!filtroExpanded)
    }

    const changeFiltro = (campo, event) => {
        let newFiltro = { ...filtro }
        newFiltro[campo] = event.target.value
        setFiltro(newFiltro)
    }

    const submit = (event) => {
        handleFiltro(filtro)
    }

    return (
        <Box mt={3}>
            <Accordion
                expanded={filtroExpanded}
                onChange={(e) => setStateAcocordion}
            >
                <AccordionSummary 
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                    style={{
                        backgroundColor: '#FFFFFF',
                    }}>
                    <FiltroSimples
                        filtro={filtro}
                        changeFiltro={changeFiltro}
                        submit={submit}
                        temFiltroAvancado={props.temFiltroAvancado}
                        clickadd={props.clickadd}
                        disabled={props.disabled}
                    />

                </AccordionSummary>
            </Accordion>
        </Box>
    )
}

export default UsuarioToolBar
