import React, { useState, useEffect, useCallback } from "react";
import ObjectHelper from "../../utils/objetcHelper";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useHistory } from "react-router-dom";
import { ClienteEsquema, FazendaDuplicarEsquema } from "./Cliente.validade";
import Loading from "../../components/Loading";
import Page from "../../components/Page";
import FooterBottons from "../../components/FooterBottons/FooterBottons";
import ClienteService from "../../services/clienteService";
import EstadoService from "../../services/estadoService";
import CidadeService from "../../services/cidadeService";
import {
  Container,
  Card,
  Grid,
  CardContent,
  TextField,
  makeStyles,
  FormControl,
} from "@material-ui/core";
import Titulo from "../../components/Titulo";
import ToastHelper from "../../utils/toastHelper";
import { validatePermission } from "../../permissoes/permissionHelper";
import { PERMISSAO } from "../../permissoes/permissoes";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { connect } from "react-redux";
import ClienteDuplicar from "./Cliente.Duplicar";
import { dataDuplicar } from "../../utils/dataDuplicateHelper";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  margin: {
    margin: theme.spacing(1),
  },
  card: {
    marginTop: "15px",
  },
  formControl: {
    margin: theme.spacing(2),
    paddingRight: "15px",
    minWidth: "100%",
  },
  errors: {
    color: "red",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justify: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  boxLoading: {
    paddingLeft: "45%",
  },
  gridFuncionalidades: {
    margin: "5px",
    width: "100%",
    backgroundColor: "#F4F6F8 !important",
    padding: "5px",
    display: "flex",
  },
  nomeFuncionalidade: {
    marginRight: "30px",
  },
  containerFuncionalidades: {
    textAlign: "-webkit-center",
  },
  gridNomefuncionalidade: {
    display: "flex",
    marginLeft: theme.spacing(1),
    alignItems: "center",
    justify: "flex-start",
  },
}));

const ClienteCadastro = (props) => {
  const history = useHistory();
  const classes = useStyles();
  const [duplicar, setDuplicar] = useState(false);
  const {
    match: { params },
  } = props;
  const {
    handleSubmit,
    setValue,
    formState: { errors },
    control,
    watch: watchReactHookForm,
    getValues,
  } = useForm({
    defaultValues: {
      cidade: "",
      estado: "",
      id: "",
      nomeRazao: "",
      cep: "",
      hectares: "",
      logradouro: "",
      complemento: "",
      urlPowerBI: "",
      fazenda: "",
    },
    resolver: yupResolver(duplicar ? FazendaDuplicarEsquema : ClienteEsquema),
  });
  const [opcoesDuplicar, setOpcoesDuplicar] = useState(dataDuplicar);

  const [visualizar, setVisualizar] = useState(false);
  const [titulo, setTitulo] = useState("Cadastro Cliente");
  const [opcoesEstado, setOpcoesEstado] = useState([]);
  const [opcoesCidade, setOpcoesCidade] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [ufEstado, setUfEstado] = useState("");

  const buscarEstados = () => {
    EstadoService.getAll().then((result) => {
      setOpcoesEstado(result.data);
    });
  };
  useEffect(() => {
    setDuplicar(opcoesDuplicar.some((i) => i.checked));
  }, [opcoesDuplicar]);

  const buscarCidades = useCallback(async (uf) => {
    if (uf.length) {
      const estado = { uf };
      let params = await ObjectHelper.createParams(estado);
      CidadeService.getByState(params).then((result) => {
        setOpcoesCidade(result.data);
      });
    }
  }, []);

  useEffect(() => {
    buscarCidades(ufEstado);
    setValue("cidade", "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ufEstado]);
  const buscarClientePorId = () => {
    if (params.id) {
      if (params.modo === "visualizar") {
        if (
          !validatePermission(
            PERMISSAO.MODULO_CLIENTE_LEITURA,
            props.permissoes
          )
        ) {
          history.push("/");
        }
        setVisualizar(true);
        setTitulo("Visualizar Cliente");
      } else {
        if (
          !validatePermission(
            PERMISSAO.MODULO_CLIENTE_ATUALIZAR,
            props.permissoes
          )
        ) {
          history.push("/");
        }
        setTitulo("Editar Cliente");
        setVisualizar(false);
      }

      ClienteService.getById(params.id).then((result) => {
        const clienteData = result.data;
        if (clienteData.estado) {
          setUfEstado(clienteData.estado.uf);
        }
        setValue("nomeRazao", clienteData.nomeRazao || "");
        setValue("hectares", clienteData.hectares || "");
        setValue("urlPowerBI", clienteData.urlPowerBI || "");
        setValue("estado", clienteData.estado || "");
        setValue("cidade", clienteData.municipio || "");
        setValue("cep", clienteData.cep || "");
        setValue("complemento", clienteData.complemento || "");
        setValue("logradouro", clienteData.logradouro || "");
        setValue("id", clienteData.id);
      });
    }
  };

  useEffect(() => {
    buscarEstados();
    buscarClientePorId();
    let dadosDuplicar = opcoesDuplicar;
    dadosDuplicar.forEach((item) => {
      item.checked = false;
    });
    setDuplicar(false);
    setOpcoesDuplicar(dadosDuplicar);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = async (dados) => {
    if (!params.id) {
      if (duplicar) {
        setIsLoading(true);
      }
      const estado = getValues("estado");
      const cidade = getValues("cidade");
      let dadosDuplicar = { fazendaOrigemId: getValues("fazenda")?.id };
      opcoesDuplicar.forEach((item) => {
        dadosDuplicar[item.nome] = item.checked;
      });
      await ClienteService.register({
        ...dados,
        estadoId: estado.id || null,
        cidadeId: cidade.id || null,
        duplicar,
        dadosDuplicar,
      })
        .then((response) => {
          duplicar
            ? ToastHelper.info(
              "Aguarde, estamos finalizando o cadastro do cliente. Você receberá um email quando estiver tudo pronto.",
              {
                autoClose: 15000,
                newestOnTop: true,
              }
            )
            : ToastHelper.success("Cliente cadastrado com sucesso!");
          history.goBack();
        })
        .catch((error) => {
            ToastHelper.error(error.response.data);
        })
        .finally(() => setIsLoading(false));
    } else {
      const estado = getValues("estado");
      const cidade = getValues("cidade");
      await ClienteService.update({
        ...dados,
        estadoId: estado.id,
        cidadeId: cidade.id,
      })
        .then((_response) => {
          ToastHelper.success("Cliente editado com sucesso!");
          history.goBack();
        })
        .catch(function (error) {
          if (error.response) {
            // Solicitação feita com resposta do servidor
            ToastHelper.error(error.response.data);
          } else if (error.request) {
            // Solicitação feita sem resposta do servidor
          } else {
            // Erro na solicitação
          }
        });
    }
  };

  return (
    <Page className={classes.root} title="Cadastro de Cliente">
      <Container maxWidth={"xl"}>
        <Titulo titulo={titulo} />
        <form
          key={1}
          className={classes.form}
          onSubmit={handleSubmit(onSubmit)}
          id="Cliente"
        >
          <Card className={classes.card}>
            <CardContent>
              <Grid container item xs={12} display="flex">
                <Grid container item xs={6} display="flex">
                  <FormControl className={classes.formControl}>
                    <Controller
                      control={control}
                      id="nomeRazao"
                      name="nomeRazao"
                      render={({ field }) => (
                        <TextField
                          {...field}
                          variant="standard"
                          margin="normal"
                          fullWidth
                          maxLength={40}
                          label="Nome *"
                          autoComplete="nomeRazao"
                          autoFocus
                          disabled={watchReactHookForm("editar") || visualizar}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputProps={{
                            maxLength: 40,
                          }}
                        />
                      )}
                    />
                    <p className={classes.errors}>
                      {errors.nomeRazao ? errors.nomeRazao.message : ""}
                    </p>
                  </FormControl>
                </Grid>
                <Grid container item xs={6} display="flex">
                  <FormControl className={classes.formControl}>
                    <Controller
                      control={control}
                      id="hectares"
                      name="hectares"
                      render={({ field }) => (
                        <TextField
                          {...field}
                          type="number"
                          variant="standard"
                          margin="normal"
                          fullWidth
                          label="Área(Ha) *"
                          autoComplete="hectares"
                          autoFocus
                          disabled={watchReactHookForm("editar") || visualizar}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      )}
                    />
                    <p className={classes.errors}>
                      {errors.hectares ? errors.hectares.message : ""}
                    </p>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container item xs={12} display="flex">
                <FormControl className={classes.formControl}>
                  <Controller
                    control={control}
                    id="urlPowerBI"
                    name="urlPowerBI"
                    render={({ field }) => (
                      <TextField
                        {...field}
                        variant="standard"
                        margin="normal"
                        fullWidth
                        label="URL Power BI"
                        autoComplete="urlPowerBI"
                        autoFocus
                        disabled={watchReactHookForm("editar") || visualizar}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{
                          maxLength: 300,
                        }}
                      />
                    )}
                  />
                  <p className={classes.errors}>
                    {errors.urlPowerBI ? errors.urlPowerBI.message : ""}
                  </p>
                </FormControl>
              </Grid>
              <Grid container item xs={12} display="flex">
                <Grid container item xs={6} display="flex">
                  <FormControl className={classes.formControl}>
                    <Controller
                      control={control}
                      id="estado"
                      name="estado"
                      render={({ field }) => (
                        <Autocomplete
                          {...field}
                          options={opcoesEstado}
                          disabled={visualizar}
                          getOptionLabel={(option) => option.nome || ""}
                          onChange={(_, data) => {
                            field.onChange(data);
                            setUfEstado(data.uf);
                          }}
                          size="small"
                          getOptionSelected={(option, value) =>
                            option?.id === value?.id
                          }
                          value={field.value}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="standard"
                              label="Estado"
                              placeholder="Selecione"
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          )}
                        />
                      )}
                    />
                    <p className={classes.errors}>
                      {errors.estado ? errors.estado.message : ""}
                    </p>
                  </FormControl>
                </Grid>
                <Grid container item xs={6} display="flex">
                  <FormControl className={classes.formControl}>
                    <Controller
                      control={control}
                      id="cidade"
                      name="cidade"
                      render={({ field }) => (
                        <Autocomplete
                          {...field}
                          options={opcoesCidade}
                          disabled={visualizar || ufEstado.length < 1}
                          getOptionLabel={(option) => option.nome || ""}
                          onChange={(_, data) => {
                            field.onChange(data);
                          }}
                          size="small"
                          getOptionSelected={(option, value) =>
                            option?.id === value?.id
                          }
                          value={field.value}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="standard"
                              label="Cidade"
                              placeholder="Selecione"
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          )}
                        />
                      )}
                    />
                    <p className={classes.errors}>
                      {errors.cidade ? errors.cidade.message : ""}
                    </p>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container item xs={12} display="flex">
                <Grid container item xs={6} display="flex">
                  <FormControl className={classes.formControl}>
                    <Controller
                      control={control}
                      id="cep"
                      name="cep"
                      render={({ field }) => (
                        <TextField
                          {...field}
                          variant="standard"
                          margin="normal"
                          fullWidth
                          label="Cep"
                          autoComplete="cep"
                          autoFocus
                          disabled={watchReactHookForm("editar") || visualizar}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputProps={{
                            maxLength: 100,
                          }}
                        />
                      )}
                    />
                    <p className={classes.errors}>
                      {errors.cep ? errors.cep.message : ""}
                    </p>
                  </FormControl>
                </Grid>
                <Grid container item xs={6} display="flex">
                  <FormControl className={classes.formControl}>
                    <Controller
                      control={control}
                      id="logradouro"
                      name="logradouro"
                      render={({ field }) => (
                        <TextField
                          {...field}
                          variant="standard"
                          margin="normal"
                          fullWidth
                          label="Logradouro"
                          autoComplete="logradouro"
                          autoFocus
                          disabled={watchReactHookForm("editar") || visualizar}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputProps={{
                            maxLength: 100,
                          }}
                        />
                      )}
                    />
                    <p className={classes.errors}>
                      {errors.logradouro ? errors.cliente.logradouro : ""}
                    </p>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container item xs={12} display="flex">
                <FormControl className={classes.formControl}>
                  <Controller
                    control={control}
                    id="complemento"
                    name="complemento"
                    render={({ field }) => (
                      <TextField
                        {...field}
                        variant="standard"
                        margin="normal"
                        fullWidth
                        label="Complemento"
                        autoComplete="complemento"
                        autoFocus
                        disabled={watchReactHookForm("editar") || visualizar}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{
                          maxLength: 100,
                        }}
                      />
                    )}
                  />
                  <p className={classes.errors}>
                    {errors.complemento ? errors.cliente.complemento : ""}
                  </p>
                </FormControl>
              </Grid>
            </CardContent>
            {visualizar || params.id ? null : (
              <ClienteDuplicar
                setValue={setValue}
                errors={errors}
                control={control}
                getValues={getValues}
                opcoesDuplicar={opcoesDuplicar}
                setOpcoesDuplicar={setOpcoesDuplicar}
              />
            )}
          </Card>
          <FooterBottons desabilitaSubmit={visualizar} />
        </form>
      </Container>
      <Loading
        open={isLoading}
        texto={
          "Duplicando Dados da fazenda... Você receberá uma notificação por email quando terminar."
        }
      />
    </Page>
  );
};

const mapStateToProps = (state) => {
  return {
    permissoes: state.permissao.permissoes,
  };
};

export default connect(mapStateToProps)(ClienteCadastro);
