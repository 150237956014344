import { SET_FILTRO_LISTAGEM_CLIENTE } from "../actions/cliente";

export const storageCliente = "cliente";

const initialState = {
  filtroListagem: {
    descedescending: false,
    order: "nomeRazao",
    pageSize: 50,
    page: 1,
    filtro: "",
    filtroExpanded: false,
  },
};

export const cliente = (state = initialState, action) => {
  switch (action.type) {
    case SET_FILTRO_LISTAGEM_CLIENTE:
      return {
        ...state,
        filtroListagem: action.filtroListagem,
      };
    default:
      return state;
  }
};
