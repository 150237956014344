import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import BackgroundImage from "../../assets/login-background.png";
import usuarioService from "../../services/usuarioService";
import { useHistory } from "react-router-dom";
import { setTokenRedefinirSenha } from "../../services/auth";

const useStyles = makeStyles(() => ({
  backgroundImage: {
    height: "100vh",
    width: "100vw",
    backgroundImage: `url(${BackgroundImage})`,
    backgroundPosition: "center",
    backgroundSize: "100vw 100vh",
    backgroundRepeat: "no-repeat",
    opacity: 0.3,
  },
}));

export default function RecuperarAcesso(props) {
  const classes = useStyles();
  const history = useHistory();

  const {
    match: { params },
  } = props;

  usuarioService
    .validarLinkRecuperarAcesso(params.id)
    .then((_response) => {
      setTokenRedefinirSenha(_response.data.tokenauth);
      history.push(`/redefinirSenhaLogin/${_response.data.usuarioId}`);
    })
    .catch((e) => {
      alert('Este não é um link válido. Ele pode ter se expirado ou já foi utilizado antes. \n\nTente novamente.')
    });

  return (
    <>
    <div className={classes.backgroundImage} />
    </>
  );
}
