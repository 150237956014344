import UsuarioPerfilFazenda from "../pages/usuarioPerfilFazenda/index"
import CadastroUsuarioPerfilFazenda from "../pages/usuarioPerfilFazenda/usuarioPerfilFazendaCadastro"

export const UsuarioPerfilFazendaRoutes = [
    {
        path: "/usuarioPerfilFazenda/listagem",
        key: "ASSOCIACAO_PERFIL_USUARIO_LISTAGEM",
        exact: true,
        component: UsuarioPerfilFazenda,
    },
    {
        path: "/usuarioPerfilFazenda/cadastro",
        key: "ASSOCIACAO_PERFIL_USUARIO_CADASTRO",
        exact: true,
        component: CadastroUsuarioPerfilFazenda,
    },
    {
        path: "/usuarioPerfilFazenda/:modo(editar|visualizar)/:id([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})",
        key: "ASSOCIACAO_PERFIL_USUARIO_VISUALIZAR_EDICAO",
        exact: true,
        component: CadastroUsuarioPerfilFazenda,
    },

];
