import React, { useState, useEffect } from "react"
import { useHistory } from "react-router-dom"
import { Controller, useForm } from "react-hook-form"
import {
	TextField,
	Grid,
	Container,
	FormControl,
	Card,
	CardContent
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles"
import usuarioService from "../../services/usuarioService"
import { usuarioModuloFazendaEsquema } from "./usuarioModuloFazenda.validate"
import { yupResolver } from "@hookform/resolvers/yup"
import Page from "../../components/Page"
import Titulo from "../../components/Titulo"
import Autocomplete from "@material-ui/lab/Autocomplete"
import FooterBottons from "../../components/FooterBottons/FooterBottons"
import moduloService from "../../services/moduloService"
import ToastHelper from '../../utils/toastHelper'
import fazendaService from "../../services/fazendaService"
import usuarioModuloFazendaService from "../../services/usuarioModuloFazendaService"
import { validatePermission } from "../../permissoes/permissionHelper"
import { PERMISSAO } from "../../permissoes/permissoes"
import { connect } from "react-redux"
import { Store } from "../../store"
import watch from "redux-watch"
import debounce from "lodash/debounce"

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: theme.palette.background.dark,
		paddingBottom: theme.spacing(3),
		paddingTop: theme.spacing(3),
	},
	margin: {
		margin: theme.spacing(1),
	},
	card: {
		marginTop: '15px',
	},
	formControl: {
		margin: theme.spacing(2),
		paddingRight: "15px",
		minWidth: '100%',
	},
	errors: {
		color: 'red',
	},
	modal: {
		display: 'flex',
		alignItems: 'center',
		justify: 'center',
	},
	paper: {
		backgroundColor: theme.palette.background.paper,
		border: '2px solid #000',
		boxShadow: theme.shadows[5],
		padding: theme.spacing(2, 4, 3),
	},
	boxLoading: {
		paddingLeft: '45%',
	},

}));

function CadastroUsuarioModuloFazenda(props) {
	const classes = useStyles();
	let history = useHistory();

	const {
		match: { params },
	} = props;

	const [editar, setEditar] = useState(false)

	const {
		handleSubmit,
		control,
		setValue,
		formState: { errors },
	} = useForm({
		defaultValues: {
			editar: false,
			usuario: null,
			modulos: [],
			clientes: [],
		},
		resolver: yupResolver(usuarioModuloFazendaEsquema),
	});

	const [titulo, setTitulo] = useState("Cadastro Usuário Módulo Cliente")
	const [visualizar, setVisualizar] = useState(false)

	const [opcoesCliente, setOpcoesCliente] = useState([])
	const [opcoesModulo, setOpcoesModulo] = useState([])
	const [opcoesUsuario, setOpcoesUsuario] = useState([])

	const buscarUsuarioModuloFazenda = async () => {
		if (params.id) {
			if (params.modo === 'visualizar') {
				if (!validatePermission(PERMISSAO.MODULO_USUARIO_MODULO_FAZENDA_LEITURA, props.permissoes)) {
					history.push("/")
				}
				setVisualizar(true)
				setTitulo('Visualizar Usuário Módulo Cliente')
				setEditar(false)
			}
			if (params.modo === 'editar') {
				if (!validatePermission(PERMISSAO.MODULO_USUARIO_MODULO_FAZENDA_ATUALIZAR, props.permissoes)) {
					history.push("/")
				}
				setEditar(true)
				setValue('editar', true)
				setTitulo('Editar Usuário Módulo Cliente')
				setVisualizar(false)
			}
			usuarioModuloFazendaService.buscarUsuarioModuloCliente(params.id).then((result) => {
				var usuarioModuloCliente = result.data
				setValue('usuario', usuarioModuloCliente.usuario)
				if (params.modo === 'visualizar') {
					setValue('modulos', [{ id: usuarioModuloCliente.modulo.id, titulo: usuarioModuloCliente.modulo.titulo }])
					setValue('clientes', [{ id: usuarioModuloCliente.cliente.id, nomeRazao: usuarioModuloCliente.cliente.nomeRazao }])
				}
				if (params.modo === 'editar') {
					setValue('modulos', { id: usuarioModuloCliente.modulo.id, titulo: usuarioModuloCliente.modulo.titulo })
					setValue('clientes', { id: usuarioModuloCliente.cliente.id, nomeRazao: usuarioModuloCliente.cliente.nomeRazao })
				}
			}).catch((error) => {
				ToastHelper.error("Erro ao buscar Usuário Modulo Cliente")
			});
		}
	};

	const onSubmit = async (dados) => {
		if (params.id) {
			var usuarioModuloFazenda = {
				id: params.id,
				usuarioId: dados.usuario.id,
				moduloId: dados.modulos.id,
				clienteId: dados.clientes.id
			}

			usuarioModuloFazendaService.atualizar(usuarioModuloFazenda).then((result) => {
				var listaUsuarioModuloCliente = result.data

				if (listaUsuarioModuloCliente.length === 0) {
					ToastHelper.success('Usuário Módulo Cliente atualizado com sucesso!')
					history.goBack()
				}

				listaUsuarioModuloCliente.forEach(usuarioModuloCliente => {
					ToastHelper.error("Já existe o cadastro do módulo " + usuarioModuloCliente.modulo + " para o cliente " + usuarioModuloCliente.cliente)
				});

			})
				.catch((error) => {
					ToastHelper.error(error.response.data)
				});
		}
		else {
			var usuarioModuloFazendaCadastro = {
				usuarioId: dados.usuario.id,
				modulos: dados.modulos.map(a => a.id),
				clientes: dados.clientes.map(a => a.id)
			}

			usuarioModuloFazendaService.cadastro(usuarioModuloFazendaCadastro).then((result) => {
				var listaUsuarioModuloCliente = result.data

				if (listaUsuarioModuloCliente.length === 0) {
					ToastHelper.success('Usuário Módulo Cliente cadastrado com sucesso!')
					history.goBack()
				}

				listaUsuarioModuloCliente.forEach(usuarioModuloCliente => {
					ToastHelper.error("Já existe o cadastro do módulo " + usuarioModuloCliente.modulo + " para o cliente " + usuarioModuloCliente.cliente)
				});

			})
				.catch((error) => {
					ToastHelper.error(error.response.data)
				});
		}

	};

	useEffect(() => {
		async function init() {
			const w = watch(Store.getState, "permissao.permissoes");
			Store.subscribe(
				w((newVal, oldVal, objectPath) => {
					if (!validatePermission(PERMISSAO.MODULO_USUARIO_MODULO_FAZENDA_CRIAR, newVal)) {
						history.push("/")
					}
				})
			)
			buscarModulos()
			buscarFazendas()

			if (params.id) {
				if (params.modo === 'editar') {
					Store.subscribe(
						w((newVal, oldVal, objectPath) => {
							if (!validatePermission(PERMISSAO.MODULO_USUARIO_MODULO_FAZENDA_ATUALIZAR, newVal)) {
								history.push("/")
							}
						})
					)
				}

				if (params.modo === 'visualizar') {
					Store.subscribe(
						w((newVal, oldVal, objectPath) => {
							if (!validatePermission(PERMISSAO.MODULO_USUARIO_MODULO_FAZENDA_LEITURA, newVal)) {
								history.push("/")
							}
						})
					)
				}

				buscarUsuarioModuloFazenda()
			}
		}
		init();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const buscarModulos = async () => {
		await moduloService.buscarTudo().then((result) => {
			var modulos = result.data
			setOpcoesModulo(modulos)
			if (modulos.length === 1) {
				setValue("modulos", modulos[0])
			}
		}).catch((error) => {
			ToastHelper.error('Erro ao buscar modulos')
		});
	}

	const buscarFazendas = async () => {
		await fazendaService.getAll().then((result) => {
			const fazendas = result.data
			setOpcoesCliente(fazendas)
			if (fazendas.length === 1) {
				setValue("clientes", [{ id: fazendas[0].id, nomeRazao: fazendas[0].nomeRazao }])
			}
		})
			.finally(() => {
				if (props.onLoaded) props.onLoaded()
			});
	};

	const handleUsuarioChange = (event, value) => {
    handleTyping(value);
  }

  const handleTyping = debounce((value) => {
		buscarUsuarios(null, value);
  }, 2000);

	const buscarUsuarios = async (event, value) => {
		if (value.length >= 3) {
			usuarioService.buscarUsuarios(value).then((result) => {
				var usuarios = result.data
				setOpcoesUsuario(usuarios)
			})
				.catch((error) => {
					ToastHelper.error('Erro ao tentar buscar usuários')
				});
		}
		if (value === '') {
			setValue('usuario', null)
			setOpcoesUsuario([])
		}
	}

	return (
		<Page className={classes.root} title='Usuário Modulo Cliente'>
			<Container maxWidth={"xl"}>
				<Titulo titulo={titulo} />
				<form
					key={1}
					className={classes.form}
					onSubmit={handleSubmit(onSubmit)}
					id='usuarioModuloCliente'
				>
					<Card className={classes.card}>
						<CardContent>
							<Grid container item xs={12} display='flex'>
								<Grid container item xs={6} display='flex'>
									<FormControl className={classes.formControl}>
										<Controller
											control={control}
											id='usuario'
											name='usuario'
											disabled={visualizar}
											render={({ field }) => (
												<Autocomplete
													{...field}
													freeSolo
													options={
														opcoesUsuario
													}
													disabled={visualizar || editar}
													getOptionLabel={(option) => option.usuario || ""}
													onChange={(_, data) => {
														field.onChange(data)
													}}
													size="small"
													getOptionSelected={(
														option,
														value
													) =>
														option?.id === value?.id
													}
													value={field.value}
													onInputChange={(event, value) => {
														handleUsuarioChange(event, value)
													}}
													renderInput={(params) => (
														<TextField
															{...params}
															variant="standard"
															label="Usuário*"
															placeholder="Selecione"
															InputLabelProps={{
																shrink: true,
															}}
															disabled={editar || visualizar}
														/>
													)}
												/>
											)}
										/>
										<p className={classes.errors}>
											{errors.usuario ? errors.usuario.message : ""}
										</p>
									</FormControl>
								</Grid>
								<Grid container item xs={6} display='flex'>
									<FormControl className={classes.formControl}>
										<Controller
											control={control}
											id='modulos'
											name='modulos'
											disabled={visualizar}
											render={({ field }) => (
												<Autocomplete
													{...field}
													options={
														opcoesModulo
													}
													disabled={visualizar}
													getOptionLabel={(option) => option.titulo || ""}
													onChange={(_, data) => {
														field.onChange(data)
													}}
													size="small"
													getOptionSelected={(
														option,
														value
													) =>
														option?.id === value?.id
													}
													value={field.value}
													multiple={!editar}
													renderInput={(params) => (
														<TextField
															{...params}
															variant="standard"
															label="Modulo*"
															placeholder="Selecione"
															InputLabelProps={{
																shrink: true,
															}}
															disabled={visualizar}
														/>
													)}
												/>
											)}
										/>
										<p className={classes.errors}>
											{errors.modulos ? errors.modulos.message : ""}
										</p>
									</FormControl>
								</Grid>
							</Grid>
							<Grid container item xs={12} display='flex'>
								<Grid container item xs={12} display='flex'>
									<FormControl className={classes.formControl}>
										<Controller
											control={control}
											id='clientes'
											name='clientes'
											disabled={visualizar}
											render={({ field }) => (
												<Autocomplete
													{...field}
													options={
														opcoesCliente
													}
													disabled={visualizar || editar}
													getOptionLabel={(option) => option.nomeRazao || ""}
													onChange={(_, data) => {
														field.onChange(data)
													}}
													size="small"
													getOptionSelected={(
														option,
														value
													) =>
														option?.id === value?.id
													}
													value={field.value}
													multiple={!editar}
													renderInput={(params) => (
														<TextField
															{...params}
															variant="standard"
															label="Clientes*"
															placeholder="Selecione"
															InputLabelProps={{
																shrink: true,
															}}
														/>
													)}
												/>
											)}
										/>
										<p className={classes.errors}>
											{errors.clientes ? errors.clientes.message : ""}
										</p>
									</FormControl>
								</Grid>
							</Grid>
						</CardContent>
					</Card>
					<FooterBottons desabilitaSubmit={visualizar} />
				</form>
			</Container>
		</Page>
	);
}

const mapStateToProps = state => {
	return {
		permissoes: state.permissao.permissoes
	};
};

export default connect(mapStateToProps)(CadastroUsuarioModuloFazenda);
