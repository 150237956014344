import api from "./portalAPI";

const service = {
  getAll(param) {
    return api.get(`/Cliente${param}`);
  },
  getById(clienteId) {
    return api.get(`/Cliente/${clienteId}`);
  },
  register(param) {
    return api.post("/Cliente", param);
  },
  update(param) {
    return api.put("/Cliente", param);
  },
  activateDeactivate(clienteId) {
    return api.get(`Cliente/AtivaDesativa/${clienteId}`);
  },
};

export default service;
