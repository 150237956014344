import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Box, Container, makeStyles } from "@material-ui/core";
import Titulo from "../../components/Titulo/index";
import Page from "../../components/Page";
import UsuarioToolBar from "./usuarioToolBar";
import UsuarioListagem from "./usuarioListagem";
import ObjectHelper from "../../utils/objectHelper";
import usuarioService from "../../services/usuarioService";
import ToastHelper from "../../utils/toastHelper";
import { validatePermission } from "../../permissoes/permissionHelper";
import { PERMISSAO } from "../../permissoes/permissoes";
import { connect } from "react-redux";
import watch from "redux-watch";
import { Store } from "../../store";

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: theme.palette.background.dark,
		paddingBottom: theme.spacing(3),
		paddingTop: theme.spacing(3),
	},
}));

function Usuario(props) {
	const history = useHistory();
	const classes = useStyles();
	const dispatch = useDispatch();

	const [loading, setLoading] = useState(false);

	const [usuarios, setUsuarios] = useState([]);
	const [filtro, setFiltro] = useState(
		useSelector((state) => state.usuario.filtroListagem)
	);

	useEffect(() => {
		const init = async () => {
			const w = watch(Store.getState, "fazenda.fazendaSelecionada");
			Store.subscribe(
				w((newVal, oldVal, objectPath) => {
					if (oldVal && newVal.id !== oldVal.id) {
						carregarDados();
					}
				})
			);

			const watchPermissoes = watch(Store.getState, "permissao.permissoes");
			Store.subscribe(
				watchPermissoes((newVal, oldVal, objectPath) => {
					if (!validatePermission(PERMISSAO.MODULO_USUARIO_LEITURA, newVal)) {
						history.push("/")
					}
				})
			)

			carregarDados();
		};
		init();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const carregarDados = () => {
		let novoFiltro = { ...filtro };
		novoFiltro.page = 1;
		novoFiltro.fazendaId = JSON.parse(localStorage.getItem("fazenda")).id;
		setFiltro(filtro);
		buscarUsuarios(novoFiltro);
	};

	const alteraFiltro = async (filtro) => {
		await dispatch({
			type: "SET_FILTRO_LISTAGEM_USUARIO",
			filtroListagem: filtro,
		});
		await setFiltro(filtro);
	};

	const handleFiltro = (filtro) => {
		dispatch({
			type: "SET_FILTRO_LISTAGEM_USUARIO",
			filtroListagem: filtro,
		});
		buscarUsuarios(filtro);
	};

	const onChangePage = async (page) => {
		let novoFiltro = { ...filtro };
		novoFiltro.page = page;
		await alteraFiltro(novoFiltro);
		await buscarUsuarios(novoFiltro);
	};

	const buscarUsuarios = async (filtro) => {
		setLoading(true);

		let filtroParaPesquisa = { ...filtro };
		let params = await ObjectHelper.createParams(filtroParaPesquisa);

		await usuarioService
			.buscarTudo(params)
			.then((result) => {
				let newFiltro = { ...filtro };
				newFiltro.totalItens = result.data.totalItens;
				newFiltro.totalPaginas = result.data.totalPaginas;
				newFiltro.pageSize = filtro.pageSize;
				newFiltro.page = filtro.page ? filtro.page : 1;
				newFiltro.order = filtro.order;
				setFiltro(newFiltro);

				var usuarios = result.data.itens;
				setUsuarios(usuarios);
				setLoading(false);
			})
			.catch((error) => {
				ToastHelper.error("Erro ao tentar buscar usuários");
				setLoading(false);
			});
	};

	const cadastrar = (item) => {
		props.history.push({
			pathname: `/usuario/cadastro`,
		});
	};

	const editar = (itemId) => {
		history.push(`/usuario/editar/${itemId}`);
	};

	const ativarDesativar = async (itemId) => {
		setLoading(true);
		await usuarioService
			.ativarDesativar({ id: itemId })
			.then((response) => {
				let novoFiltro = { ...filtro };
				buscarUsuarios(novoFiltro);
				setLoading(false);
				ToastHelper.success("Ativado/Desativado com sucesso!");
			})
			.catch((error) => {
				ToastHelper.error("Ocorreu um erro");
				setLoading(false);
			});
	};

	const deletar = async (item) => {
		setLoading(true);
		await usuarioService
			.deletar(item.id)
			.then((response) => {
				ToastHelper.success("Cadastro excluído com sucesso!");
				let novoFiltro = { ...filtro };
				buscarUsuarios(novoFiltro);
			})
			.catch((error) => {
				ToastHelper.error("Ocorreu um erro");
				setLoading(false);
			});
	};

	const visualizar = async (item) => {
		history.push(`/usuario/visualizar/${item.id}`)
	}

	const alterarSenha = (itemId) => {
		history.push(`/usuario/senha/${itemId}`)
	}

	const mudaOrdenacao = (numeroColuna, orientacao, colunas) => {
		let novoFiltro = { ...filtro };

		novoFiltro.descedescending = !novoFiltro.descedescending;
		novoFiltro.order = colunas[numeroColuna]?.field;

		alteraFiltro(novoFiltro);
		buscarUsuarios(novoFiltro);
	};

	return (
		<Page className={classes.root} title="Usuários">
			<Container maxWidth={'xl'}>
				<Titulo titulo="Usuários" />
				<UsuarioToolBar
					handleFiltro={handleFiltro}
					filtroInicial={filtro}
					clickadd={cadastrar}
					disabled={!validatePermission(PERMISSAO.MODULO_USUARIO_CRIAR, props.permissoes)}
				/>
				<Box mt={3}>
					<UsuarioListagem
						data={usuarios}
						onChangePage={(page) => onChangePage(page)}
						filtro={filtro}
						isLoading={loading}
						pageSize={filtro.pageSize}
						editar={editar}
						editarPermissao={validatePermission(PERMISSAO.MODULO_USUARIO_ATUALIZAR, props.permissoes)}
						ativarDesativar={ativarDesativar}
						alterarSenha={alterarSenha}
						deletarPermissao={validatePermission(PERMISSAO.MODULO_USUARIO_EXCLUIR, props.permissoes)}
						deletar={deletar}
						visualizar={visualizar}
						visualizarPermissao={validatePermission(PERMISSAO.MODULO_USUARIO_LEITURA, props.permissoes)}
						mudaOrdenacao={mudaOrdenacao}
					/>
				</Box>
			</Container>
		</Page>
	)
}

const mapStateToProps = (state) => {
	return {
		permissoes: state.permissao.permissoes,
	};
};

export default connect(mapStateToProps)(Usuario);
