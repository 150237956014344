import { STORE_PERMISSAO } from '../actions/permissao'

export const storagePermissao = 'permissao'

const initialState = {
    permissoes: [],
}

export const permissao = (state = initialState, action) => {
    switch (action.type) {
        case STORE_PERMISSAO:
            return {
                ...state,
                permissoes: action.permissao,
            }
        default:
            return state
    }
}