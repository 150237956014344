import { TextField } from "@material-ui/core";
import React from "react";

const TelefoneInput = (props) => {
  const TYPES = {
    OITO: "9999-9999",
    NOVE: "99999-9999",
    SNOVE: "9 9999-9999",

    DDDOITO: "(99)9999-9999",
    DDDNOVE: "(99)99999-9999",
    DDDSNOVE: "(99)9 9999-9999",

    SDDDOITO: "(99) 9999-9999",
    SDDDNOVE: "(99) 99999-9999",
    SDDDSNOVE: "(99) 9 9999-9999",
  };

  const MAX_LENGTH = clear(TYPES.SDDDNOVE).length;
  const { onChange } = props;

  let value = clear(props.value);

  if (value) {
    value = applyMask(value, TYPES[getMask(value)]);
  }

  function onLocalChange(ev) {
    let value = clear(ev.target.value);
    const mask = getMask(value);

    let nextLength = value.length;

    if (nextLength > MAX_LENGTH) return;

    value = applyMask(value, TYPES[mask]);

    ev.target.value = value;

    onChange(ev);
  }

  function getMask(value) {
    return value.length > 10 ? "SDDDNOVE" : "SDDDOITO";
  }

  function applyMask(value, mask) {
    let result = "";

    let inc = 0;

    Array.from(value).forEach((letter, index) => {
      while (!mask[index + inc].match(/[0-9]/)) {
        result += mask[index + inc];
        inc++;
      }
      result += letter;
    });

    return result;
  }

  function clear(value) {
    return value && value.replace(/[^0-9]/g, "");
  }

  return (
    <TextField
      {...props}
      value={value}
      innerRef={props.ref}
      onChange={onLocalChange}
    />
  );
};

export default TelefoneInput;
