import { SET_FILTRO_LISTAGEM_USUARIO_PERFIL_FAZENDA } from '../actions/usuarioPerfilFazenda'

export const storageUsuarioPerfilFazenda = 'usuarioPerfilFazenda'

const initialState = {
    filtroListagem: {
        pageSize: 100,
        page: 1,
        filtro: '',
        order: 'usuario',
        filtroExpanded: false
    },
}

export const usuarioPerfilFazenda = (state = initialState, action) => {
    switch (action.type) {
        case SET_FILTRO_LISTAGEM_USUARIO_PERFIL_FAZENDA:
            return {
                ...state,
                filtroListagem: action.filtroListagem,
            }
        default:
            return state
    }
}
