import React from "react";
import { Switch } from "react-router-dom";
import { RouteWithSubRoutes } from "./RouteWithSubRoutes";
import "../shared/styles/404page.scss";
import { createBrowserHistory } from "history";

const history = createBrowserHistory();

export const RenderRoutes = ({ routes }) => (
  <Switch>
    {routes.map((route) => {
      return (
        <RouteWithSubRoutes key={route.key} history={history} {...route} />
      );
    })}
    <body>
      <section id="not-found">
        <div className="circles">
          <p>
            404<br></br>
            <small>Pagina não encontrada</small>
          </p>
          <span className="circle big"></span>
          <span className="circle med"></span>
          <span className="circle small"></span>
        </div>
      </section>
    </body>
  </Switch>
);
