import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  AppBar,
  Toolbar,
  FormControl,
  InputLabel,
  Select,
  Input,
  Chip,
  MenuItem,
} from "@material-ui/core";
import UserMenu from "../../components/UserMenu";
import fazendaService from "../../services/fazendaService";
import { useDispatch } from "react-redux";
import { STORE_FAZENDA } from "../../store/actions/fazenda";
import watch from "redux-watch"
import { Store } from "../../store"
import { useHistory } from "react-router-dom"
import PermissaoService from "../../services/permisaoService"
import { STORE_PERMISSAO } from "../../store/actions/permissao"

const MenuProps = {
  PaperProps: {
    style: {
      width: 250,
      marginTop: 40,
    },
  },
};

const useStyles = makeStyles((theme) => ({
  root: {
    position: "fixed",
    width: "100%",
    top: 0,
  },
  appBar: {
    display: "flex",
    backgroundColor: "white",
    width: "100%",
  },
  logo: {
    marginRight: "15px",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 250,
  },
  right: {
    marginLeft: "auto",
  },
}));

export default function TopBar(props) {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [fazendas, setFazendas] = useState([]);
  const [fazendaSelecionada, setFazendaSelecionada] = useState("");

  useEffect(() => {
    async function init() {
      const w = watch(Store.getState, "fazenda.fazendaSelecionada");
      Store.subscribe(
        w((newVal, oldVal, objectPath) => {
          if (oldVal && newVal.id !== oldVal.id) {
            setFazenda(newVal);
          } else {
            setFazenda(newVal);
          }
        })
      );

      let fazenda = JSON.parse(localStorage.getItem("fazenda"));
      await setFazendaSelecionada(fazenda);
      await getFazendas();
    }
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getFazendas = async () => {
    await fazendaService
      .getAll()
      .then((response) => {
        const itens = response.data;
        setFazendas(itens);
        if (itens.length === 1) {
          setFazenda(itens[0]);
        }
      })
      .finally(() => {
        if (props.onLoaded) props.onLoaded();
      });
  };

  const setFazenda = (fazenda) => {
    setFazendaSelecionada(fazenda);
    localStorage.setItem("fazenda", JSON.stringify(fazenda));
    dispatch({
      type: STORE_FAZENDA,
      fazenda: fazenda,
    });

    getPermissoes(fazenda)

  };

  const getPermissoes = (fazenda) => {
    if (fazenda) {
        PermissaoService.buscarTudo(fazenda.id).then((result) => {
          localStorage.setItem("permissoes", JSON.stringify(result.data));
            dispatch({
                type: STORE_PERMISSAO,
                permissao: result.data,
            });
        })
        .catch((error) => {
            //setIsLoading(false);
        });
    };
}

  const goHome = () => {
    history.push("/");
  };

  return (
    <div className={classes.root}>
      <AppBar position="static" className={classes.appBar}>
        <Toolbar>
          <img
            src={process.env.PUBLIC_URL + "/images/logo.png"}
            alt="Home"
            width={150}
            className={classes.logo}
            onClick={() => goHome()}
            style={{ cursor: "pointer" }}
          />
          <FormControl className={classes.formControl}>
            <InputLabel id="fazenda-menu-label">Clientes</InputLabel>
            <Select
              id="fazenda-menu-select"
              value={fazendaSelecionada}
              onChange={(e) => setFazenda(e.target.value)}
              input={<Input />}
              renderValue={(selected) => (
                <div className={classes.chips}>
                  {selected && (
                    <Chip
                      size="small"
                      key={selected.id}
                      label={selected.nomeRazao}
                      className={classes.chip}
                    />
                  )}
                </div>
              )}
              MenuProps={MenuProps}
            >
              {fazendas.map((obj) => (
                <MenuItem key={obj.id} value={obj}>
                  {obj.nomeRazao}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <div className={classes.right}>
            <UserMenu {...props} />
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
}
