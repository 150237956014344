import React from "react";
import { useHistory } from "react-router";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import { getUsername } from "../services/auth";
import {
  Card,
  CardHeader,
  Avatar,
  Menu,
  MenuItem,
  Tooltip,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    maxHeight: 70,
  },
  avatar: {
    cursor: "pointer",
  },
}));

const UserMenu = (props) => {
  const classes = useStyles();
  const history = useHistory();

  const sair = () => {
    localStorage.clear();
    window.location.href = "logout";
    history.go();
  };

  return (
    <PopupState variant="popover" popupId="demo-popup-menu">
      {(popupState) => (
        <React.Fragment>
          <Card elevation={0} className={classes.root}>
            <CardHeader
              avatar={
                <Tooltip title={`${getUsername()}`}>
                  <Avatar
                    className={classes.avatar}
                    alt={getUsername()}
                    src="/broken-image.jpg"
                    {...bindTrigger(popupState)}
                  />
                </Tooltip>
              }
            />
          </Card>
          <Menu {...bindMenu(popupState)}>
            <MenuItem
              onClick={() => {
                sair();
                popupState.close();
              }}
            >
              Sair
            </MenuItem>
          </Menu>
        </React.Fragment>
      )}
    </PopupState>
  );
};

export default UserMenu;
