import React, { useState, useEffect } from "react";
import {
  CssBaseline,
  CircularProgress,
  Button,
  TextField,
  FormControlLabel,
  Checkbox,
  Grid,
  Box,
  Container,
  Paper,
  InputAdornment,
  IconButton
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import { Copyright } from "../../components/Copyright";
import loginService from "../../services/loginService";
import Alert from "@material-ui/lab/Alert";
import {
  isAuthenticated,
  setToken,
  setTokenRedefinirSenha,
} from "../../services/auth";
import BackgroundImage from "../../assets/login-background.png";
import KeepMountedModal from "./forget/modal";

const useStyles = makeStyles((theme) => ({
  backgroundImage: {
    height: "100vh",
    width: "100vw",
    backgroundImage: `url(${BackgroundImage})`,
    backgroundPosition: "center",
    backgroundSize: "100vw 100vh",
    backgroundRepeat: "no-repeat",
    opacity: 0.3,
  },
  root: {
    height: "100vh",
    width: "100vw",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    top: 0,
    left: 0,
  },
  paper: {
    padding: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  logo: {
    width: "300px",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  linkForget: {
    paddingTop: 10
  },
}));

export default function Login(props) {
  const classes = useStyles();
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const [remember, setRemember] = useState(false);
  const [loginInvalido, setLoginInvalido] = useState(false);
  const [mensagemLoginInvalido, setMensagemLoginInvalido] = useState(
    "Login ou senha inválidos"
  );
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    if (isAuthenticated()) {
      props.history.push("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLoginChange = (e) => {
    setLogin(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleRememberChange = (e) => {
    setRemember(!remember);
  };

  const handleSubmit = (e) => {
    setIsLoading(true);
    e.preventDefault();
    loginService
      .login(login.trim(), password)
      .then((result) => {
        if (result.data.statusPadraoSenha) {
          props.history.push(`/redefinirSenhaLogin/${result.data.id}`);
          setTokenRedefinirSenha(result.data.token);
        } else {
          setToken(result.data.token);
          props.history.push("/");
        }
      })
      .catch((e) => {
        setMensagemLoginInvalido(e.response.data);
        setLoginInvalido(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const validadeSubmit = (e) => {
    // eslint-disable-next-line no-unused-expressions
    e.target.length > 4 ? handleSubmit(e) : null; // Valida se o comando de submit está vindo do form de login
    return false;
  }

  const handleShowPassword = () => {
    setShowPassword((state) => !state);
  }

  return (
    <>
      <div className={classes.backgroundImage} />
      <div className={classes.root}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Paper className={classes.paper} elevation={5}>
            <Grid className={classes.avatar}>
              <img
                src={process.env.PUBLIC_URL + "/images/logo.png"}
                alt="SGAGRI"
                className={classes.logo}
              />
            </Grid>
            <form className={classes.form} onSubmit={validadeSubmit} noValidate>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="login"
                label="Usuário"
                name="login"
                autoComplete="login"
                autoFocus
                onChange={handleLoginChange}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Senha"
                type={showPassword ? "text" : "password"}
                id="password"
                autoComplete="current-password"
                onChange={handlePasswordChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleShowPassword}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
              <Grid container>
                <Grid item xs>
                  <FormControlLabel
                    control={<Checkbox value="remember" color="primary" />}
                    label="Lembrar"
                    onChange={handleRememberChange}
                  />
                </Grid>
                <Grid item className={classes.linkForget}>
                  <KeepMountedModal />
                </Grid>
                {loginInvalido && (
                  <Alert
                    severity="error"
                    onClose={() => {
                      setLoginInvalido(false);
                    }}
                  >
                    {mensagemLoginInvalido}
                  </Alert>
                )}
              </Grid>

              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                {isLoading ? (
                  <CircularProgress size={25} color="secondary" />
                ) : (
                  "Entrar"
                )}
              </Button>
              {/* Comentado até integrar as funcionalidades */}
              {/*<Grid container>
                <Grid item xs>
                  <Link href="#" variant="body2">
                    Esqueceu a senha?
                  </Link>
                </Grid>
                <Grid item>
                  <Link href="#" variant="body2">
                    Cadastre-se
                  </Link>
                </Grid>
              </Grid>*/}
            </form>
          </Paper>
          <Box mt={2}>
            <Copyright />
          </Box>
        </Container>
      </div>
    </>
  );
}
