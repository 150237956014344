import api from "./portalAPI";

const service = {
  getAll() {
    return api.get(`/Cidade`);
  },
  getByState(param) {
    return api.get(`/Cidade/PorEstado${param}`);
  },
};

export default service;
