import React, { useState, useEffect } from "react";
import Titulo from "../../components/Titulo/index";
import { Box, Container, makeStyles } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import ClienteService from "../../services/clienteService";
import Page from "../../components/Page";
import Toolbar from "../../../src/components/BasicPageTopBar/Toolbar";
import ToastHelper from "../../utils/toastHelper";
import ObjectHelper from "../../utils/objetcHelper";
import { validatePermission } from "../../permissoes/permissionHelper";
import { PERMISSAO } from "../../permissoes/permissoes";
import { connect } from "react-redux";
import ClienteListagem from "./ClienteListagem";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

function Cliente(props) {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [clientes, setClientes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filtro, setFiltro] = useState(
    useSelector((state) => state.cliente.filtroListagem)
  );

  useEffect(() => {
    buscarClientes(filtro);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const alteraFiltro = (filtro) => {
    dispatch({
      type: "SET_FILTRO_LISTAGEM_CLIENTE",
      filtroListagem: filtro,
    });
    setFiltro(filtro);
  };

  const handleFiltro = (filtro) => {
    dispatch({
      type: "SET_FILTRO_LISTAGEM_CLIENTE",
      filtroListagem: filtro,
    });
    buscarClientes(filtro);
    setFiltro(filtro);
  };

  const buscarClientes = async (filtro) => {
    setLoading(true);
    let novoFiltro = { ...filtro };
    let params = await ObjectHelper.createParams(novoFiltro);
    ClienteService.getAll(params)
      .then((response) => {
        if (response.status === 200) {
          let newFiltro = { ...filtro };
          newFiltro.totalItens = response.data.totalItens;
          newFiltro.totalPaginas = response.data.totalPaginas;
          newFiltro.pageSize = novoFiltro.pageSize;
          newFiltro.page = filtro.page ? filtro.page : 1;
          newFiltro.order = filtro.order;

          alteraFiltro(newFiltro);
          setClientes(response.data.itens);
          setLoading(false);
        } else {
          ToastHelper.error(response.statusText);
          setLoading(false);
        }
      })
      .catch((error) => {
        ToastHelper.error("Erro ao tentar buscar clientes");
        setLoading(false);
      });
  };

  const onChangePage = async (page) => {
    let novoFiltro = { ...filtro };
    novoFiltro.page = page;
    alteraFiltro(novoFiltro);
    await buscarClientes(novoFiltro);
  };

  const cadastrar = () => {
    props.history.push({
      pathname: `/cliente/cadastro`,
    });
  };

  const visualizar = async (item) => {
    history.push(`/cliente/visualizar/${item.id}`);
  };

  const editar = (itemId) => {
    history.push(`/cliente/editar/${itemId}`);
  };

  const ativarDesativar = async (itemId) => {
    setLoading(true);
    await ClienteService.activateDeactivate(itemId).then(async (response) => {
      if (response.status === 200) {
        let novoFiltro = { ...filtro };
        await buscarClientes(novoFiltro);
        setLoading(false);
      } else {
        ToastHelper.error(response.statusText);
        setLoading(false);
      }
    });
  };

  const mudaOrdenacao = (numeroColuna, orientacao, colunas) => {
    let novoFiltro = { ...filtro };
    const colunaNumero = numeroColuna < 0 ? 0 : numeroColuna;
    novoFiltro.descedescending =
      orientacao === "asc" || orientacao === "" ? false : true;
    novoFiltro.order = colunas[colunaNumero]?.field;

    alteraFiltro(novoFiltro);
    buscarClientes(novoFiltro);
  };

  return (
    <Page className={classes.root} title="Clientes">
      <Container maxWidth={"xl"}>
        <Titulo titulo="Clientes" />
        <Toolbar
          handleFiltro={handleFiltro}
          filtroInicial={filtro}
          clickadd={cadastrar}
          temFiltroAvancado={false}
          disabled={
            !validatePermission(
              PERMISSAO.MODULO_CLIENTE_CRIAR,
              props.permissoes
            )
          }
        />
        <Box mt={3}>
          <ClienteListagem
            data={clientes}
            onChangePage={(page) => onChangePage(page)}
            filtro={filtro}
            isLoading={loading}
            pageSize={filtro.pageSize}
            editar={editar}
            editarPermissao={validatePermission(
              PERMISSAO.MODULO_CLIENTE_ATUALIZAR,
              props.permissoes
            )}
            ativarDesativar={ativarDesativar}
            visualizar={visualizar}
            visualizarPermissao={validatePermission(
              PERMISSAO.MODULO_CLIENTE_LEITURA,
              props.permissoes
            )}
            mudaOrdenacao={mudaOrdenacao}
          />
        </Box>
      </Container>
    </Page>
  );
}

const mapStateToProps = (state) => {
  return {
    permissoes: state.permissao.permissoes,
  };
};
export default connect(mapStateToProps)(Cliente);
