import Home from "../pages/home/Home";
import { EnsureAuthentication } from "./EnsureAuthentication";
import { RenderRoutes } from "./RenderRoutes";
import { UsuarioRoute } from "./UsuarioRoute";
import { PerfilRoute } from "./PerfilRoute";
import { UsuarioModuloFazendaRoutes } from "./usuarioModuloFazendaRoutes";
import { UsuarioPerfilFazendaRoutes } from "./usuarioPerfilFazendaRoutes";
import { ClienteRoute } from "./ClienteRoute";

export const routes = [
  {
    path: "/",
    key: "APP",
    component: EnsureAuthentication,
    routes: [
      {
        path: "/",
        key: "INDEX",
        exact: true,
        component: Home,
      },
      {
        path: "/:fazendaId([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})",
        key: "INDEX_COM_FAZENDA",
        exact: true,
        component: Home,
      },
      {
        path: "/usuario",
        key: "USUARIO",
        component: RenderRoutes,
        routes: UsuarioRoute,
      },
      {
        path: "/perfil",
        key: "PERFIL",
        component: RenderRoutes,
        routes: PerfilRoute,
      },
      {
        path: "/usuarioModuloFazenda",
        key: "ASSOCIACAO_MODULO_USUARIO",
        component: RenderRoutes,
        routes: UsuarioModuloFazendaRoutes,
      },
      {
        path: "/usuarioPerfilFazenda",
        key: "ASSOCIACAO_PERFIL_USUARIO",
        component: RenderRoutes,
        routes: UsuarioPerfilFazendaRoutes,
      },
      {
        path: "/cliente",
        key: "CLIENTE",
        component: RenderRoutes,
        routes: ClienteRoute,
      },
      /**
       * Outras rotas dentro do escopo do app entram a partir daqui.
       * */
    ],
  },
];
