import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Box, Container, makeStyles } from '@material-ui/core'
import Titulo from '../../components/Titulo/index'
import Page from '../../components/Page'
import ToolbarAssociacaoUsuarioModulo from './usuarioModuloFazendaToolBar'
import AssociacaoUsuarioModuloListagem from './usuarioModuloFazendaListagem'
import ObjectHelper from '../../utils/objectHelper'
import usuarioModuloFazendaService from "../../services/usuarioModuloFazendaService"
import ToastHelper from '../../utils/toastHelper'
import { validatePermission } from "../../permissoes/permissionHelper"
import { PERMISSAO } from "../../permissoes/permissoes"
import { connect } from "react-redux"
import fazendaService from "../../services/fazendaService"
import moduloService from "../../services/moduloService"
import usuarioService from "../../services/usuarioService"
import { Store } from "../../store"
import watch from "redux-watch"

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3),
    },
}))

function UsuarioModuloFazenda(props) {
    const history = useHistory()
    const classes = useStyles()
    const dispatch = useDispatch()

    const [loading, setLoading] = useState(false)

    const [usuariosModuloFazenda, setUsuariosModuloFazenda] = useState([])
    const [filtro, setFiltro] = useState(
        useSelector((state) => state.usuarioModuloFazenda.filtroListagem)
    )
    const [opcoesCliente, setOpcoesCliente] = useState([])
    const [opcoesModulo, setOpcoesModulo] = useState([])
    const [opcoesUsuario, setOpcoesUsuario] = useState([])


    useEffect(() => {
        const init = async () => {

            const w = watch(Store.getState, "permissao.permissoes");
            Store.subscribe(
                w((newVal, oldVal, objectPath) => {
                    if (!validatePermission(PERMISSAO.MODULO_USUARIO_MODULO_FAZENDA_LEITURA, newVal)) {
                        history.push("/")
                    }
                })
            )

            buscarFazendas()
            buscarModulos()
            let novoFiltro = { ...filtro }
            novoFiltro.page = 1
            setFiltro(filtro)
            buscarUsuarioModuloFazenda(novoFiltro)
        }
        init()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const alteraFiltro = async (filtro) => {
        await dispatch({
            type: 'SET_FILTRO_LISTAGEM_USUARIO_MODULO_FAZENDA',
            filtroListagem: filtro,
        })
        await setFiltro(filtro)
    }

    const handleFiltro = (filtro) => {
        dispatch({
            type: 'SET_FILTRO_LISTAGEM_USUARIO_MODULO_FAZENDA',
            filtroListagem: filtro,
        })
        buscarUsuarioModuloFazenda(filtro)
    }

    const onChangePage = async (page) => {
        let novoFiltro = { ...filtro }
        novoFiltro.page = page
        await alteraFiltro(novoFiltro)
        await buscarUsuarioModuloFazenda(novoFiltro)
    }

    const buscarUsuarioModuloFazenda = async (filtro) => {

        setLoading(true);

        let filtroParaPesquisa = { ...filtro }
        let params = await ObjectHelper.createParams(filtroParaPesquisa)

        await usuarioModuloFazendaService.buscarTudo(params).then((result) => {
            let newFiltro = { ...filtro }
            newFiltro.totalItens = result.data.totalItens
            newFiltro.totalPaginas = result.data.totalPaginas
            newFiltro.pageSize = filtro.pageSize
            newFiltro.page = filtro.page ? filtro.page : 1
            newFiltro.order = filtro.order
            setFiltro(newFiltro)

            var usuarios = result.data.itens
            setUsuariosModuloFazenda(usuarios)
            setLoading(false);

        })
            .catch((error) => {
                ToastHelper.error('Erro ao tentar buscar associação de usuario com módulos')
                setLoading(false)
            });
    }

    const cadastrar = (item) => {
        props.history.push({
            pathname: `/usuarioModuloFazenda/cadastro`,
        })
    }

    const editar = (itemId) => {
        history.push(`/usuarioModuloFazenda/editar/${itemId}`)
    }

    const ativarDesativar = async (itemId) => {
        setLoading(true)
        await usuarioModuloFazendaService.ativarDesativar({ id: itemId }).then(
            (response) => {
                let novoFiltro = { ...filtro }
                buscarUsuarioModuloFazenda(novoFiltro)
                setLoading(false)
                ToastHelper.success('Ativado/Desativado com sucesso!')
            })
            .catch((error) => {
                ToastHelper.error("Ocorreu um erro")
                setLoading(false)
            });
    }

    const deletar = async (item) => {
        setLoading(true)
        await usuarioModuloFazendaService.deletar(item.id).then((response) => {
            ToastHelper.success('Cadastro excluído com sucesso!')
            let novoFiltro = { ...filtro }
            buscarUsuarioModuloFazenda(novoFiltro)
        })
            .catch((error) => {
                ToastHelper.error("Ocorreu um erro")
                setLoading(false)
            });
    }

    const visualizar = async (item) => {
        history.push(`/usuarioModuloFazenda/visualizar/${item.id}`)
    }

    const mudaOrdenacao = (numeroColuna, orientacao, colunas) => {
        let novoFiltro = { ...filtro }

        novoFiltro.descedescending = !novoFiltro.descedescending
        if (colunas[numeroColuna]?.field === 'usuario') {
            novoFiltro.order = 'Usuario.NomeUsuario'
        }
        else if (colunas[numeroColuna]?.field === 'email') {
            novoFiltro.order = 'Usuario.Email'
        }
        else if (colunas[numeroColuna]?.field === 'modulo') {
            novoFiltro.order = 'Modulo.Titulo'
        }
        else if (colunas[numeroColuna]?.field === 'cliente') {
            novoFiltro.order = 'Fazenda.NomeRazao'
        }

        alteraFiltro(novoFiltro)
        buscarUsuarioModuloFazenda(novoFiltro)
    }

    const buscarFazendas = async () => {
        setLoading(true)
        await fazendaService.getAll().then((result) => {
            const itens = result.data
            setOpcoesCliente(itens)
            setLoading(false)
        })
            .finally(() => {
                if (props.onLoaded) props.onLoaded()
            });
    };

    const buscarModulos = async () => {
        setLoading(true)
        await moduloService.buscarTudo().then((result) => {
            var modulos = result.data
            setOpcoesModulo(modulos)
        }).catch((error) => {
            ToastHelper.error('Erro ao buscar modulos')
            setLoading(false)
        });
    }

    const buscarUsuarios = async (event, value) => {
        if (value.length >= 3) {
            usuarioService.buscarUsuarios(value).then((result) => {
                var usuarios = result.data
                setOpcoesUsuario(usuarios)
            })
                .catch((error) => {
                    ToastHelper.error('Erro ao tentar buscar usuários')
                });
        }
        if (value === '') {
            let filtroNovo = { ...filtro }
            filtroNovo.usuarioId = null
            filtroNovo.usuarioNome = ''
            alteraFiltro(filtroNovo)
        }
    }

    return (
        <Page className={classes.root} title="Usuário Módulo Cliente">
            <Container maxWidth={'xl'}>
                <Titulo titulo="Associação de Usuário Módulo Cliente" />
                <ToolbarAssociacaoUsuarioModulo
                    handleFiltro={handleFiltro}
                    filtroInicial={filtro}
                    clickadd={cadastrar}
                    disabled={!validatePermission(PERMISSAO.MODULO_USUARIO_MODULO_FAZENDA_EXCLUIR, props.permissoes)}
                    filtroAtivo
                    temFiltroAvancado
                    opcoesCliente={opcoesCliente}
                    opcoesModulo={opcoesModulo}
                    opcoesUsuario={opcoesUsuario}
                    buscarUsuarios={buscarUsuarios}

                />
                <Box mt={3}>
                    <AssociacaoUsuarioModuloListagem
                        data={usuariosModuloFazenda}
                        onChangePage={(page) => onChangePage(page)}
                        filtro={filtro}
                        isLoading={loading}
                        pageSize={filtro.pageSize}
                        editar={editar}
                        editarPermissao={validatePermission(PERMISSAO.MODULO_USUARIO_MODULO_FAZENDA_ATUALIZAR, props.permissoes)}
                        ativarDesativar={ativarDesativar}
                        deletarPermissao={validatePermission(PERMISSAO.MODULO_USUARIO_MODULO_FAZENDA_EXCLUIR, props.permissoes)}
                        deletar={deletar}
                        visualizar={visualizar}
                        visualizarPermissao={validatePermission(PERMISSAO.MODULO_USUARIO_MODULO_FAZENDA_LEITURA, props.permissoes)}
                        mudaOrdenacao={mudaOrdenacao}
                    />
                </Box>
            </Container>
        </Page>
    )
}

const mapStateToProps = state => {
    return {
        permissoes: state.permissao.permissoes
    };
};

export default connect(mapStateToProps)(UsuarioModuloFazenda);
