import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { Box, Divider, Hidden, makeStyles, TextField } from "@material-ui/core";
import NavItem from "./NavItem";
import FormControl from "@material-ui/core/FormControl";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { GlobalHotKeys } from "react-hotkeys";
import { getTelasDoMenuRapido } from "./MenuRapidoHelper";
import SearchIcon from "@material-ui/icons/Search";
import CadastroIcon from "@material-ui/icons/AddCircle";
import PersonIcon from "@mui/icons-material/Person";
import Tooltip from "@material-ui/core/Tooltip";
import { PERMISSAO } from "../../permissoes/permissoes";
import AssignmentReturnedIcon from "@material-ui/icons/AssignmentReturned";
import { connect } from "react-redux";
import GroupIcon from "@mui/icons-material/Group";
import SupervisedUserListagem from "@material-ui/icons/SupervisedUserCircle";
import AgricultureIcon from "@mui/icons-material/Agriculture";

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: "#fff",
  },
  mobileDrawer: {
    width: 250,
  },
  desktopDrawer: {
    width: 250,
    top: 75,
    height: "calc(100% - 75px)",
  },
  avatar: {
    cursor: "pointer",
    width: 64,
    height: 64,
  },
  formControl: {
    margin: "",
    minWidth: "100%",
  },
  divider: {},
  iconMarginRigth: {
    marginTop: "10px",
    marginBottom: "10px",
    maxHeight: "25px",
    minWidth: "25px",
    marginRight: "25px",
    marginLeft: "7px",
    cursor: "pointer",
  },
}));

function getItems() {
  var json = {
    list: [
      {
        id: 1,
        items: [
          {
            id: 1,
            name: "Cadastros",
            index: 1,
            icon: CadastroIcon,
            subitems: [
              {
                id: 11,
                index: 11,
                href: "/usuario/listagem",
                icon: PersonIcon,
                name: "Usuários",
                modulo: PERMISSAO.MODULO_USUARIO_LEITURA,
              },
              {
                id: 12,
                index: 12,
                href: "/perfil/listagem",
                icon: GroupIcon,
                name: "Perfis",
                modulo: PERMISSAO.MODULO_PERFIL_LEITURA,
              },
              {
                id: 13,
                index: 13,
                href: "/usuarioModuloFazenda/listagem",
                icon: AssignmentReturnedIcon,
                name: "Associação Usuário Módulo",
                modulo: PERMISSAO.MODULO_USUARIO_MODULO_FAZENDA_LEITURA,
              },
              {
                id: 14,
                index: 14,
                href: "/usuarioPerfilFazenda/listagem",
                icon: SupervisedUserListagem,
                name: "Associação Usuário Perfil",
                modulo: PERMISSAO.MODULO_USUARIO_PERFIL_FAZENDA_LEITURA,
              },
              {
                id: 15,
                index: 15,
                href: "/cliente/listagem",
                icon: AgricultureIcon,
                name: "Clientes",
                modulo: PERMISSAO.MODULO_CLIENTE_LEITURA,
              },
            ],
          },
        ],
      },
    ],
  };
  return json;
}

let hotKeysKeyMap = {
  MENU_RAPIDO: "ctrl+m",
};

let menuRapidoRef = React.createRef();

const NavBar = ({ onMobileClose, openMobile, ...rest }) => {
  const classes = useStyles();
  //const location = useLocation()
  const history = useHistory();

  let telasDoMenuRapido = getTelasDoMenuRapido(getItems, rest.permissoes);

  const acessarMenuRapido = async () => {
    await rest.handleDrawerOpen();
    await menuRapidoRef.current.focus();
  };

  let hotKeyshandlers = {
    MENU_RAPIDO: acessarMenuRapido.bind(),
  };

  const [telaAtual] = useState(undefined);

  useEffect(
    (onMobileClose, openMobile) => {
      if (openMobile && onMobileClose) {
        onMobileClose();
      }
    }
    //[location.pathname]
  );

  useEffect(() => {
    if (rest.open) {
      menuRapidoRef.current.focus();
    }
  }, [rest.open]);

  const onChangeMenu = (item, href) => {
    if (href) history.push(href.value);
  };

  const content = (
    <GlobalHotKeys keyMap={hotKeysKeyMap} handlers={hotKeyshandlers}>
      <Box
        height="100%"
        display="flex"
        flexDirection="column"
        className={classes.root}
      >
        {rest.open && (
          <Box alignItems="center" display="flex" flexDirection="column" p={2}>
            <FormControl className={classes.formControl}>
              <Autocomplete
                name="menuRapido"
                id="menuRapido"
                options={telasDoMenuRapido}
                value={telaAtual}
                defaultValue={telaAtual}
                getOptionLabel={(option) => option.label}
                size="small"
                onChange={(item, item2) => onChangeMenu(item, item2)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    inputRef={menuRapidoRef}
                    name="menuRapido"
                    id="menuRapido"
                    variant="standard"
                    label="Menu rápido (Ctrl + M)"
                    placeholder="Digite aqui"
                    required={true}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )}
              />
            </FormControl>
          </Box>
        )}
        {!rest.open && (
          <Tooltip title={"Menu rápido"}>
            <SearchIcon
              className={rest.open ? classes.icon : classes.iconMarginRigth}
              onClick={() => acessarMenuRapido()}
            />
          </Tooltip>
        )}
        <Divider className={classes.divider} />

        <NavItem
          handleDrawerOpen={() => rest.handleDrawerOpen()}
          handleDrawerClose={() => rest.handleDrawerClose()}
          open={rest.open}
          getItems={() => getItems()}
        />
      </Box>
    </GlobalHotKeys>
  );
  return (
    <>
      {/*<Hidden lgUp>
                <Drawer
                    classes={{ paper: classes.mobileDrawer }}
                    onClose={onMobileClose}
                    open={openMobile}
                    variant="permanent"
                >
                    {content}
                    classes={{ paper: classes.desktopDrawer }}
                </Drawer>
            </Hidden>*/}

      <Hidden>{content}</Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

NavBar.defaultProps = {
  openMobile: false,
};

const mapStateToProps = (state) => {
  return {
    permissoes: state.permissao.permissoes,
  };
};

export default connect(mapStateToProps)(NavBar);
