import React from "react";
import { Controller, useForm } from "react-hook-form";
import { redefinirSenhaLoginEsquema } from "./redefinirSenhaLogin.validate";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  CssBaseline,
  Button,
  TextField,
  Grid,
  Box,
  Container,
  Paper,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Copyright } from "../../components/Copyright";
import BackgroundImage from "../../assets/login-background.png";
import usuarioService from "../../services/usuarioService";
import { useHistory } from "react-router-dom";
import ToastHelper from "../../utils/toastHelper";
import { removeTokenRedefinirSenha } from "../../services/auth";
import { InputAdornment, IconButton } from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { useState } from "react";

const useStyles = makeStyles((theme) => ({
  backgroundImage: {
    height: "100vh",
    width: "100vw",
    backgroundImage: `url(${BackgroundImage})`,
    backgroundPosition: "center",
    backgroundSize: "100vw 100vh",
    backgroundRepeat: "no-repeat",
    opacity: 0.3,
  },
  errors: {
    color: "red",
    fontSize: 13,
    margin: 0,
  },
  formControl: {
    margin: theme.spacing(2),
    paddingRight: "15px",
    minWidth: "100%",
  },
  root: {
    height: "100vh",
    width: "100vw",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    top: 0,
    left: 0,
  },
  paper: {
    padding: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  logo: {
    width: "300px",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    width: "30%",
  },
  submitCancel: {
    margin: theme.spacing(3, 0, 2),
    color: "white",
    width: "30%",
    backgroundColor: "rgb(165, 163, 163)",
  },
  containerButtons: {
    display: "flex",
    justifyContent: "space-between",
  },
  title: {
    color: "rgb(30, 129, 30)",
    fontWeight: "inherit",
  },
  titleSencudary: {
    color: "rgb(30, 129, 30)",
  },
  text: {
    fontWeight: "bold",
  },
}));

export default function RedefinirSenhaUsuarioLogin(props) {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const classes = useStyles();
  const history = useHistory();
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      senhaNova: "",
      senhaNovaConfirmar: "",
    },
    resolver: yupResolver(redefinirSenhaLoginEsquema),
  });

  const {
    match: { params },
  } = props;

  const onSubmit = (dados) => {
    usuarioService
      .redefinirSenhaLogin({ ...dados, id: params.id })
      .then((_response) => {
        ToastHelper.success("Senha atualizada com sucesso!");
        removeTokenRedefinirSenha();
        history.push("/login");
      })
      .catch((e) => {
        ToastHelper.error(e.response.data);
      });
  };

  const cancel = () => {
    removeTokenRedefinirSenha();
    history.push("/login");
  };

  const handleShowPassword = () => {
    setShowPassword((state) => !state);
  }

  const handleShowConfirmPassword = () => {
    setShowConfirmPassword((state) => !state);
  }

  return (
    <>
      <div className={classes.backgroundImage} />
      <div className={classes.root}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Paper className={classes.paper} elevation={5}>
            <Grid className={classes.avatar}>
              <img
                src={process.env.PUBLIC_URL + "/images/logo.png"}
                alt="SGAGRI"
                className={classes.logo}
              />
            </Grid>
            <h2 className={classes.title}>REDEFINIR SENHA</h2>
            <form
              key={1}
              className={classes.form}
              onSubmit={handleSubmit(onSubmit)}
              id="usuarioRedefinirSenhaLogin"
            >
              <Grid>
                <Controller
                  control={control}
                  id="senhaNova"
                  name="senhaNova"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      label="Nova senha"
                      type={showPassword ? "text" : "password"}
                      id="password"
                      autoComplete="current-password"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleShowPassword}
                            >
                              {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                    />
                  )}
                />
                <p className={classes.errors}>
                  {errors.senhaNova ? errors.senhaNova.message : ""}
                </p>
              </Grid>
              <Grid>
                <Controller
                  control={control}
                  id="senhaNovaConfirmar"
                  name="senhaNovaConfirmar"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      name="password"
                      label="Confirme sua nova senha"
                      type={showConfirmPassword ? "text" : "password"}
                      id="confirm-password"
                      autoComplete="current-password"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleShowConfirmPassword}
                            >
                              {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                    />
                  )}
                />
                <p className={classes.errors}>
                  {errors.senhaNovaConfirmar
                    ? errors.senhaNovaConfirmar.message
                    : ""}
                </p>
              </Grid>
              <Grid className={classes.containerButtons}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="inherit"
                  onClick={cancel}
                  className={classes.submitCancel}
                >
                  Cancelar
                </Button>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                >
                  Salvar
                </Button>
              </Grid>
            </form>
            <Grid>
              <h4 className={classes.titleSencudary}>
                Por que tenho que fazer isso?
              </h4>
              <p className={classes.text}>
                Por questões de segurança do sistema, as senhas das contas
                cadastradas precisam ser redefinidas. Agradecemos a compreenção.
              </p>
            </Grid>
          </Paper>
          <Box mt={2}>
            <Copyright />
          </Box>
        </Container>
      </div>
    </>
  );
}
