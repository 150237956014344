import React, { useState, useEffect } from "react";
import { Controller } from "react-hook-form";
import Autocomplete from "@material-ui/lab/Autocomplete";

import {
  Container,
  Grid,
  TextField,
  makeStyles,
  FormControl,
} from "@material-ui/core";
import Titulo from "../../components/Titulo";
import { Paper } from "@mui/material";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import fazendaService from "../../services/fazendaService";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  margin: {
    margin: theme.spacing(1),
  },
  card: {
    marginTop: "15px",
  },
  formControl: {
    margin: theme.spacing(2),
    paddingRight: "15px",
    minWidth: "100%",
  },
  errors: {
    color: "red",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justify: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  boxLoading: {
    paddingLeft: "45%",
  },
  gridFuncionalidades: {
    margin: "5px",
    width: "100%",
    backgroundColor: "#F4F6F8 !important",
    padding: "5px",
    display: "flex",
  },
  nomeFuncionalidade: {
    marginRight: "30px",
  },
  containerFuncionalidades: {
    textAlign: "-webkit-center",
  },
  gridNomefuncionalidade: {
    display: "flex",
    marginLeft: theme.spacing(1),
    alignItems: "center",
    justify: "flex-start",
  },
}));

const ClienteDuplicar = (props) => {
  const classes = useStyles();

  const [selecionarTudo, setSelecionarTudo] = useState(false);
  const [opcoesFazenda, setOpcoesFazenda] = useState([]);

  const selecionarOpcao = (checked, index, obrigatorios, dependentes) => {
    let state = [...props.opcoesDuplicar];
    state[index].checked = checked;
    if (dependentes) {
      dependentes.forEach((nomeDependente) => {
        const status = state.find((i) => i.nome === nomeDependente).checked;
        state.find((i) => i.nome === nomeDependente).checked = status
          ? checked
          : false;
      });
    }
    if (obrigatorios && checked) {
      obrigatorios.forEach((nomeObrigatorio) => {
        state.find((i) => i.nome === nomeObrigatorio).checked = checked;
      });
    }

    props.setOpcoesDuplicar(state);
  };

  const buscarFazendas = async () => {
    fazendaService.getAll().then((response) => {
      setOpcoesFazenda(response.data);
    });
  };

  useEffect(() => {
    buscarFazendas();
  }, []);

  const changeSelecionarTudo = (checked) => {
    let state = [...props.opcoesDuplicar];
    state.forEach((e) => (e.checked = checked));
    props.setOpcoesDuplicar(state);
    setSelecionarTudo(checked);
  };

  return (
    <Container maxWidth={"xl"}>
      <Titulo titulo={"Duplicar Dados da Fazenda"} />
      <Grid container item xs={6} display="flex">
        <FormControl className={classes.formControl}>
          <Controller
            control={props.control}
            id="fazenda"
            name="fazenda"
            render={({ field }) => (
              <Autocomplete
                {...field}
                options={opcoesFazenda}
                getOptionLabel={(option) => option.nomeRazao || ""}
                onChange={(_, data) => {
                  field.onChange(data);
                }}
                size="small"
                getOptionSelected={(option, value) => option?.id === value?.id}
                value={field.value}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Fazenda Origem"
                    placeholder="Selecione"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )}
              />
            )}
          />
          <p className={classes.errors}>
            {props.errors.fazenda ? props.errors.fazenda.message : ""}
          </p>
        </FormControl>
      </Grid>
      <Grid
        className={classes.containerFuncionalidades}
        container
        alignItems="center"
        justify="center"
      >
        <Grid item xs={12} display="flex">
          <Paper className={classes.gridFuncionalidades} display="flex">
            <Grid
              item
              xs={5}
              className={classes.gridNomefuncionalidade}
              display="flex"
            >
              <h4 className={classes.nomeFuncionalidade}>Selecionar Tudo</h4>
            </Grid>
            <Grid item xs={7} display="flex">
              <Checkbox
                color="primary"
                checked={selecionarTudo || false}
                indeterminate={selecionarTudo === undefined}
                onChange={(event, checked) => changeSelecionarTudo(checked)}
              />
            </Grid>
          </Paper>
        </Grid>
        {props.opcoesDuplicar.map((item, index) => {
          return (
            <Grid item xs={12} display="flex" key={index}>
              <Paper className={classes.gridFuncionalidades} display="flex">
                <Grid
                  item
                  xs={5}
                  className={classes.gridNomefuncionalidade}
                  display="flex"
                >
                  <h4 className={classes.nomeFuncionalidade}>{item.label}</h4>
                </Grid>
                <Grid item xs={7} display="flex">
                  <FormControlLabel
                    name={item.nome}
                    id={item.nome}
                    control={
                      <Checkbox
                        color="primary"
                        checked={item.checked || false}
                        onChange={(_, checked) =>
                          selecionarOpcao(
                            checked,
                            index,
                            item.obrigatorios,
                            item.dependentes
                          )
                        }
                      />
                    }
                    label={"Duplicar"}
                  />
                </Grid>
              </Paper>
            </Grid>
          );
        })}
      </Grid>
    </Container>
  );
};

export default ClienteDuplicar;
