import { SET_FILTRO_LISTAGEM_PERFIL } from '../actions/perfil';

export const storagePerfil = 'perfil';

const initialState = {
    filtroListagem: {
        descedescending: false,
        order: 'Nome',
        pageSize: 100,
        page: 1,
        filtro: '',
        filtroExpanded: false
    },
};

export const perfil = (state = initialState, action) => {
    switch (action.type) {
        case SET_FILTRO_LISTAGEM_PERFIL:
            return {
                ...state,
                filtroListagem: action.filtroListagem,
            };
        default:
            return state;
    }
};
